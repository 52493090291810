'use strict';


import {isDesktopApp} from './utility';

// https://tauri.localhost/unauthorized or
// tauri://localhost/unauthorized
// there is a different protocol+host in windows and linux/osx
// redirect not working with tauri scheme: "Redirection to URL with a scheme that is not HTTP(S)"
export const REDIRECT_URI = window.location.protocol  + '//' + window.location.host + '/unauthorized';
// export const REDIRECT_URI = 'http://localhost:1420/unauthorized'; // dev redirect

export const PKCE_CLIENT_ID = '7miok65v4fr03mkpsk84vpqcg7';

const roles_checked: boolean = undefined;
export const ROLES_CHECK = {"successful": roles_checked};

export const BEARER_TOKEN = {
  "access_token": "",
  "create_date_in_millis": 0,
  "expires_in": 0,
  "id_token": "",
  "refresh_token": "",
  "token_type": "Bearer"
};
export const EIGHT_HOURS_IN_MILLIS = 28800000; // 8 hours

// const API_PREFIX_WEB = 'http://localhost:8282'; this is https-port, it will not work. Use port 8181 instead
// const API_PREFIX_WEB = 'http://localhost:8080';

// const API_PREFIX_WEB = 'http://localhost:8181'; //dev url
const API_PREFIX_WEB = ''; // prod url
// const API_PREFIX_WEB = 'https://www.yourcalendarwidget.com:443';

export const API_PREFIX_TAURI = 'https://www.yourcalendarwidget.com:443'; // prod url
// export const API_PREFIX_TAURI = API_PREFIX_WEB; // dev url
export var API_PREFIX = isDesktopApp() ? API_PREFIX_TAURI : API_PREFIX_WEB;
