import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {TaskComponent} from './task/task.component';
import {TaskOverviewComponent} from './task-overview/task-overview.component';
import {TaskOverviewMenuComponent} from './task-overview-menu/task-overview-menu.component';
import {TaskMenuComponent} from './task-menu/task-menu.component';
import {UnauthorizedComponent} from './unauthorized/unauthorized.component';
import {RolesGuard} from './roles.guard';
import {TasklistaccessComponent} from './tasklistaccess/tasklistaccess.component';

const routes: Routes = [
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
    data: { showHeader: false, showSidebar: false, navigationlevel: '99' }
  },
  {
    path: 'task',
    component: TaskComponent,
    canActivate: [RolesGuard],
    data: {showHeader: true,
      menu: TaskMenuComponent, showSidebar: true, showBreadcrumb: true, headerTitle: 'Aufgabe', navigationlevel : '2'}
  },
  {
    path: 'task/:id',
    component: TaskComponent,
    canActivate: [RolesGuard],
    data: {showHeader: true,
      menu: TaskMenuComponent, showSidebar: true, showBreadcrumb: true, headerTitle: 'Aufgabe', navigationlevel : '2'}
  },
  { path: '', redirectTo: '/tasks', pathMatch: 'full' },
  {
    path: 'tasks',
    component: TaskOverviewComponent,
    canActivate: [RolesGuard],
    data: {
      showHeader: true,
      menu: TaskOverviewMenuComponent,
      showSidebar: true,
      showBreadcrumb: true,
      headerTitle: 'Aufgaben',
      navigationlevel: '1'
    }
  },
  {
    path: 'tasks/:id/access',
    component: TasklistaccessComponent,
    canActivate: [RolesGuard],
    data: {
      showHeader: true,
      showSidebar: true,
      showBreadcrumb: true,
      headerTitle: 'Berechtigungen',
      navigationlevel: '2'
    }
  },
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: false })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
