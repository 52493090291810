import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {EventbusEvent} from './data-model/eventbus_event';

@Injectable({
  providedIn: 'root'
})
export class EventBusService {

  private menuActionSource = new Subject<any>();
  private dataLoadedSource = new Subject<any>();
  private handleEvent = new Subject<any>();
  menuActionChange$ = this.menuActionSource.asObservable();
  dataLoaded$ = this.dataLoadedSource.asObservable();
  eventFired$ = this.handleEvent.asObservable();

  constructor() {
  }

  emitMenuAction(action: string): void {
    this.menuActionSource.next(action);
  }

  emitDataLoaded(data: any): void {
    // timeout required to make dynamic-loaded menu receive the emitted event
    setTimeout(() => {
      this.dataLoadedSource.next(data);
    }, 1);
  }

  emitEvent(event: EventbusEvent): void {
    this.handleEvent.next(event);
  }

}
