<header>
  <div id="header-div" class="navbar navbar-light bg-light shadow-sm bg-white">
    <div class="container d-flex justify-content-between">
      <a routerLink="/" class="navbar-brand d-flex align-items-center">
        <div class="circle">S</div>
        <strong style="margin-left: 10px;">Sand</strong> box
      </a>
      <div *ngIf="user && user.isAuthenticated" style="float: right; padding-right: 25px;"><b>{{user.name}}</b> (<a href="/logout">logout</a>)
      </div>
    </div>
  </div>
  <div *ngIf="headerTitle" class="navbar navbar-light bg-light shadow-sm bg-white"
       style="border-top:1px solid #eee; z-index: 1;">
    <div class="container d-flex justify-content-between">
      <!--      <a routerLink="/" class="navbar-brand d-flex align-items-center">-->
      <!--        <strong style="margin-left: 10px;">{{headerTitle}}</strong>-->
      <!--      </a>-->
      <div class="navbar-brand d-flex align-items-center">
        <span  [style.visibility]="getBackButtonVisibility()" role='button' (click)="onBack()" class="bi-chevron-left"></span><strong
        style="margin-left: 10px;">{{headerTitle}}</strong>
      </div>
      <div id="menu-div">
        <!-- Use ng-template to ensure that the generated components end up in the right place -->
        <ng-template #container>
        </ng-template>
      </div>
    </div>
  </div>
</header>


<div id="main-content" [ngClass]="headerTitle ? 'bg-light with-title': 'bg-light'">
  <div class="container">

    <div>
      <div id="router-content" [@routeAnimations]="getRouteAnimationData()">
        <router-outlet></router-outlet>
      </div>
    </div>

  </div>
</div>


<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ng-template #popupContainer>
        </ng-template>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div>


<div id="decisionModal" class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{decisionModalModel.title}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p [innerHTML]="decisionModalModel.message"></p>
      </div>
      <div class="modal-footer">
        <button id="questionModalCancel" [hidden]="!decisionModalModel.cancelText" type="button" class="btn btn-light"
                data-dismiss="modal">{{decisionModalModel.cancelText}}</button>
        <button id="questionModalConfirm" (click)="onConfirmQuestion(decisionModalModel.result)" type="button"
                class="btn {{decisionModalModel.confirmClass}}">{{decisionModalModel.confirmText}}</button>
      </div>
    </div>
  </div>
</div>
<div id="snackbar">Some text some message..</div>
<div id="loading-indicator">Lade...</div>
<div *ngIf="user && user.isAuthenticated" id="search-popup" style="display: none" >
  <app-search/>
</div>
