export enum EventbusEventType {
  MODAL_QUESTION = 'modal_question',
  MODAL_INFO = 'modal_info',
  MODAL_RESULT = 'modal_result',
  F2_PRESSED = 'f2_pressed',
  CONTROL_N_PRESSED = 'control_n_pressed',
  CONTROL_SHIFT_N_PRESSED = 'control_shift_n_pressed',
  CONTROL_S_PRESSED = 'control_s_pressed',
  CONTROL_E_PRESSED = 'control_e_pressed',
  CONTROL_T_PRESSED = 'control_t_pressed',
  SHOW_SEARCH = 'show_search',
  HIDE_SEARCH = 'hide_search',
  DELETE = 'delete',
  SAVE = 'save',
  DELETE_TASK = 'delete_task',
  DELETE_TASKLIST = 'delete_tasklist'
}
