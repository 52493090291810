import {Component, EventEmitter, Input, OnInit, OnChanges, Output} from '@angular/core';
import {DateChangedEvent} from './DateChangeEvent';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css']
})
export class DatePickerComponent implements OnInit, OnChanges {

  guid = Math.random().toString().replace('.', '');

  constructor() {
  }

  @Input() value: string;
  @Output() valueChange = new EventEmitter<any>();

  ngOnInit(): void {
    setTimeout(() => {
      const that = this;
      // @ts-ignore
      $('#' + this.guid).datepicker({
        dateFormat: 'dd.mm.yy',
        onSelect: date => {
          const parts = date.split('.');
          const jsDate = new Date(Date.UTC(parts[2], parts[1] - 1, parts[0]));
          console.log('date selected ' + date);
          that.valueChange.emit(jsDate);
        }
      });
    }, 1);
  }

  ngOnChanges(changes): void {
    console.log(changes.value.currentValue);
  }

  onChange(event): void {
    console.log(event.target.value);

    if (!event.target.value) {
      this.valueChange.emit(null);
      return;
    }

    let parts = event.target.value.split('.');
    if (parts.length === 1) {

      if (parts[0].length === 3 || parts[0].length === 1) {
        parts[0] = '0' + parts[0];
      }
      // i.e. 15 or 15th of current month
      if (parts[0].length <= 2) {
        const date = new Date();
        date.setDate(parts[0]);
        parts = [date.getDate(), date.getMonth() + 1, date.getFullYear()];
      }
      // i.e. 1510 for 15 oct
      if (parts[0].length === 4) {
        const date = new Date();
        date.setDate(parts[0].substr(0, 2));
        date.setMonth(parts[0].substring(2, parts[0].length));
        parts = [date.getDate(), date.getMonth(), date.getFullYear()];
      }
    }
    this.valueChange.emit(new Date(Date.UTC(parts[2], parts[1] - 1, parts[0])));
  }

}
