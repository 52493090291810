import {Component, NgZone, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PKCE_CLIENT_ID, REDIRECT_URI, ROLES_CHECK} from '../constants';
import {UserService} from '../data-services/user.service';
import {isDesktopApp, readFile, tauriInvoke, writeFile} from '../utility';
import { open } from '@tauri-apps/plugin-shell';
import { listen } from '@tauri-apps/api/event';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.css']
})
export class UnauthorizedComponent implements OnInit {

  redirect = REDIRECT_URI;
  isAuthenticated = false;
  username = '';
  url = '/oauth2/authorization/client1';
  oauthCodeEventListenerDeregistration;

  constructor(protected route: ActivatedRoute, protected userService: UserService, protected router: Router, private ngZone: NgZone) {
  }

  async openUrlInDefaultBrowserOfOS(){
    open(this.url);
  }

  ngOnDestroy(): void {
    if(this.oauthCodeEventListenerDeregistration){
      this.oauthCodeEventListenerDeregistration();
    }
  }

  async listenForEvents(){
    this.oauthCodeEventListenerDeregistration = await listen<string>('oauth_code', (event) => {
      console.log("event received: 'oauth_code'");
      this.getOAuthToken(event.payload);
      // this.getOAuthToken(event.payload);
    });
  }

  ngOnInit(): void {
    if(isDesktopApp()){
      this.listenForEvents();
    }
    const codeParam: string = this.route.snapshot.queryParamMap.get('code');
    // console.log("init view. code = " + codeParam);

    this.route.queryParams.subscribe(params => {
      if (!!params.username) {
        this.username = params.username;
        this.isAuthenticated = true;
      }
    });

    if (PKCE_CLIENT_ID && isDesktopApp() && codeParam == null) {
      readFile().then((data) => {
        let noAccessTokenAvailable = !data || data.length < 100;
        if (noAccessTokenAvailable) {
          this.setupPKCEAuth();
        }
      }).catch((error) => {
        console.log(error);
      });
    }else {
      console.log("no oauth setup");
    }

    this.route.queryParams.subscribe(params => {
        if (params.code && isDesktopApp()) {
          this.getOAuthToken(params.code)
        }
      }
    );
  }

  async getOAuthToken(code) {
    console.error("getting token");
    const that = this;

    const verifier = await readFile();

    this.userService.fetchAccessToken(verifier, code, this.redirect).subscribe({
      next: async function (token) {
        console.error("Token fetched");
        token.create_date_in_millis = Date.now();
        await writeFile(JSON.stringify(token));
        console.error("compete called, navigating now...");
        ROLES_CHECK.successful = undefined; // reset state so that the role will be checked with the new token
        that.ngZone.run(() =>
            that.router.navigate(['tasks'], {replaceUrl: true})
        );
      },
      error: async function (err) {
        writeFile('');
      }
    });
  }

  async setupPKCEAuth() {
    console.info("setup oauth");
    let port = await tauriInvoke()('start_server');
    this.redirect = "http://localhost:" + port;
    console.log("oauth using redirect uri: " + this.redirect);
    let state = this.generateCodeVerifier();
    let codeVerifier = this.generateCodeVerifier();
    let code_challenge = await this.generateCodeChallengeFromVerifier(codeVerifier);
    this.url = "https://yourcalendarwidget.auth.eu-central-1.amazoncognito.com/login?response_type=code&client_id=" + PKCE_CLIENT_ID + "&code_challenge=" + code_challenge + "&code_challenge_method=S256&&scope=openid&state=" + state + "&redirect_uri=" + this.redirect;
    console.log("oauth using url: " + this.url);
    await writeFile(codeVerifier);
    console.log("oauth verifier written");

  }

  // GENERATING CODE VERIFIER
  dec2hex(dec) {
    return ("0" + dec.toString(16)).substr(-2);
  }
  generateCodeVerifier() {
    var array = new Uint32Array(56 / 2);
    window.crypto.getRandomValues(array);
    return Array.from(array, this.dec2hex).join("");
  }

  // GENERATING CODE CHALLENGE FROM VERIFIER
  sha256(plain) {
    // returns promise ArrayBuffer
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);
    return window.crypto.subtle.digest("SHA-256", data);
  }

  base64urlencode(a) {
    var str = "";
    var bytes = new Uint8Array(a);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      str += String.fromCharCode(bytes[i]);
    }
    return btoa(str)
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, "");
  }

  async generateCodeChallengeFromVerifier(v) {
    var hashed = await this.sha256(v);
    var base64encoded = this.base64urlencode(hashed);
    return base64encoded;
  }

  protected readonly isDesktopApp = isDesktopApp;
}
