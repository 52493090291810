<div style="margin-right: 25px">

  <div class="btn-group" *ngIf="data && data.id">
    <button type="button" class="btn btn-light dropdown-toggle " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      Mehr
    </button>
    <div class="dropdown-menu" >
      <a (click)="action(EventbusEventType.DELETE)" class="btn dropdown-item"><i class="bi-trash" role="img"></i> Löschen</a>
    </div>
  </div>

  <button class="btn btn-primary" (click)="action(EventbusEventType.SAVE)">Speichern</button>
</div>
