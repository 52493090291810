import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API_PREFIX} from '../constants';
import {ServiceType} from '../data-model/servicetype';
import {DataService} from './dataservice';
import {Entity} from '../data-model/entity';

@Injectable({
  providedIn: 'root'
})
export class ServicetypeService implements DataService{

  private serviceURL = API_PREFIX + '/api/v1/servicetype';

  constructor(private http: HttpClient) {
  }

  get(id: number): Observable<Entity> {
    return this.http.get<ServiceType>(this.serviceURL + '/' + id);
    }
    getAll(): Observable<Entity[]> {
      return this.http.get<ServiceType[]>(this.serviceURL);
    }

  create(serviceType: ServiceType): Observable<ServiceType> {
    return this.http.post<ServiceType>(this.serviceURL, serviceType);
  }

  delete(id: number): Observable<ServiceType> {
    return this.http.delete<ServiceType>(this.serviceURL + '/' + id);
  }

  getServiceTypes(): Observable<ServiceType[]> {
    return this.http.get<ServiceType[]>(this.serviceURL);
  }

  getServiceType(id: number): Observable<ServiceType> {
    return this.http.get<ServiceType>(this.serviceURL + '/' + id);
  }

  private handleError(error: HttpErrorResponse) {
    console.error(error);
  }
}
