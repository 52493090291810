import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {User} from '../data-model/user';
import {API_PREFIX, REDIRECT_URI} from '../constants';
import {Token} from '../data-model/token';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private serviceURL = API_PREFIX + '/api/v1/me';


  constructor(private http: HttpClient) {
  }

  getUsername(): Observable<User> {
    return this.http.get<User>(this.serviceURL);
  }

  fetchAccessToken(verifier, code, redirect): Observable<Token> {
    var url = "https://yourcalendarwidget.auth.eu-central-1.amazoncognito.com/oauth2/token";

    let body = new URLSearchParams();
    body.set('grant_type', 'authorization_code');
    body.set('client_id', '7miok65v4fr03mkpsk84vpqcg7');
    body.set('redirect_uri', redirect); // REDIRECT_URI
    body.set('code', code);
    body.set('code_verifier', verifier);


    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    return this.http.post<Token>(url, body.toString(), options);
  }

  refreshToken(refreshtoken): Observable<Token> {
    var url = "https://yourcalendarwidget.auth.eu-central-1.amazoncognito.com/oauth2/token";

    let body = new URLSearchParams();
    body.set('grant_type', 'refresh_token');
    body.set('client_id', '7miok65v4fr03mkpsk84vpqcg7');
    body.set('refresh_token', refreshtoken);

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    return this.http.post<Token>(url, body.toString(), options);
  }

  private handleError(error: HttpErrorResponse) {
    console.error(error);
  }
}
