import {Injectable} from '@angular/core';
import {Stock} from '../data-model/stock';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API_PREFIX} from '../constants';
import {Tag} from '../data-model/tag';

@Injectable({
  providedIn: 'root'
})
export class TagService {

  private serviceURL = API_PREFIX + '/api/v1/tag';

  constructor(private http: HttpClient) {
  }

  createTag(tag: Tag): Observable<Tag> {
    return this.http.post<Tag>(this.serviceURL, tag);
  }

  getTags(): Observable<Tag[]> {
    return this.http.get<Tag[]>(this.serviceURL);
  }

}
