import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API_PREFIX} from '../constants';
import {HasPosition} from '../data-model/has_position';
import {Tasklist} from '../data-model/tasklist';
import {ShareDetails} from '../data-model/share_details';
import {TasklistParent} from '../data-model/tasklist_parent';
import {Task} from '../data-model/task';

@Injectable({
  providedIn: 'root'
})
export class TasklistService {

  private serviceURL = API_PREFIX + '/api/v1/tasklist';

  constructor(private http: HttpClient) {
  }

  getTasklistWithTasks(id: String): Observable<Tasklist> {
    if(!!id){
      return this.http.get<Tasklist>(this.serviceURL + '/' + id);
    }
    return this.http.get<Tasklist>(this.serviceURL);
  }

  create(tasklist: Tasklist): Observable<Tasklist> {
    return this.http.post<Tasklist>(this.serviceURL, tasklist);
  }

  createTask(listId: string, task: Task): Observable<Task> {
    if (!listId) {
      listId = 'inbox';
    }
    return this.http.post<Task>(this.serviceURL + '/' + listId + '/task', task);
  }

  patchTask(listId: string, taskId: string,  patchData: any): Observable<Task> {
    if (!listId) {
      listId = 'inbox';
    }
    return this.http.patch<Task>(this.serviceURL + '/' + listId + '/task' + '/' + taskId, patchData);
  }

  patch(id: number, task: any): Observable<Tasklist> {
    return this.http.patch<Tasklist>(this.serviceURL + '/' + id, task);
  }

  update(task: Tasklist): Observable<Tasklist> {
    return this.http.post<Tasklist>(this.serviceURL, task);
  }

  updatePositions(tasks: HasPosition[]): Observable<Tasklist> {
    return this.http.post<Tasklist>(this.serviceURL + '/positions', tasks);
  }
  updateParent(parent: TasklistParent): Observable<Tasklist> {
    return this.http.post<Tasklist>(this.serviceURL + '/parent', parent);
  }

  delete(id: number): Observable<Tasklist> {
    return this.http.delete<Tasklist>(this.serviceURL + '/' + id);
  }

  getTasklists(): Observable<Tasklist[]> {
    return this.http.get<Tasklist[]>(this.serviceURL + "/summary");
  }

  get(id: number): Observable<Tasklist> {
    return this.http.get<Tasklist>(this.serviceURL + '/' + id);
  }

  getShares(tasklistId: number): Observable<ShareDetails> {
    return this.http.get<ShareDetails>(this.serviceURL + '/' + tasklistId + '/share');
  }

  deleteShare(tasklistId: number, user: string): Observable<Tasklist> {
    return this.http.delete<Tasklist>(this.serviceURL + '/' + tasklistId + '/share?user=' + user);
  }

  addShare(tasklistId: number, user: string): Observable<Tasklist> {
    return this.http.post<Tasklist>(this.serviceURL + '/' + tasklistId + '/share', user);
  }

  private handleError(error: HttpErrorResponse) {
    console.error(error);
  }
}
