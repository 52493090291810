import {animate, animateChild, group, query, style, transition, trigger} from '@angular/animations';

const animationAndTime = '200ms ease';
const moveInPercent = '2%';
const moveInPercentInverse = '-2%';

const navigateForward = [
  style({ position: 'relative' }),
  query(':enter, :leave', [
    style({
      position: 'absolute',
      top: 0,
      left: moveInPercent,
      width: '100%'
    })
  ]),
  query(':enter', [
    style({ left: moveInPercent, opacity: 0.0 })
  ]),
  query(':leave', [
    style({ left: '0', opacity: 1.0 })
  ]),
  // query(':leave', animateChild()),
  group([
    query(':leave', [
      animate(animationAndTime, style({ left: moveInPercentInverse, opacity: 0.0 }))
    ]),
    query(':enter', [
      animate(animationAndTime, style({ left: '0', opacity: 1.0 }))
    ]),
  ]),
];
const navigateBack = [
  style({ position: 'relative' }),
  query(':enter, :leave', [
    style({
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%'
    })
  ]),
  query(':enter', [
    style({ left: moveInPercentInverse, opacity: 0.0 })
  ]),
  query(':leave', [
    style({ opacity: 1.0 })
  ]),
  // query(':leave', animateChild()),
  group([
    query(':leave', [
      animate(animationAndTime, style({ left: moveInPercent, opacity: 0.0 }))
    ]),
    query(':enter', [
      animate(animationAndTime, style({ left: '0%', opacity: 1.0 }))
    ]),
  ]),
];

export const slideInAnimation =
  trigger('routeAnimations', [
    transition('2 => 1', navigateBack),
    transition('3 => 2', navigateBack),
    transition('3 => 1', navigateBack),
    transition('1 => 2', navigateForward),
    transition('1 => 3', navigateForward),
    transition('2 => 3', navigateForward),
    transition('* <=> *', navigateForward)
  ]);
