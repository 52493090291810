<select class="form-control form-control-sm" (change)="updateValue($event)">

  <option value=-1></option>
  <option *ngFor="let item of persons" (change)="updateValue($event)"
          [value]=item.id
          [attr.selected]="(value && value.id==item.id) ? true : null">
    {{item[column]}} {{item.firstname}} {{item.lastname}}
  </option>

</select>

