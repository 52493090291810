import {Component, OnInit} from '@angular/core';
import {TasklistService} from '../data-services/tasklist.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ShareDetails} from '../data-model/share_details';

@Component({
  selector: 'app-tasklistaccess',
  templateUrl: './tasklistaccess.component.html',
  styleUrls: ['./tasklistaccess.component.css']
})
export class TasklistaccessComponent implements OnInit {

  currentTasklistId: number;
  shares: ShareDetails[];
  shareWith: string;

  constructor(private tasklistService: TasklistService, protected route: ActivatedRoute, protected router: Router) {
  }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.currentTasklistId = params.id;
      this.onLoadShares();
    });
  }

  onLoadShares(): void {
    this.shareWith = '';
    this.tasklistService.getShares(this.currentTasklistId).subscribe({
      next: shares => this.onLoadSuccess(shares),
      error: err => console.log(err)
    });
  }

  onLoadSuccess(shares): void {
    this.shares = shares;
  }

  onDeleteShare(username: string) {
    this.tasklistService.deleteShare(this.currentTasklistId, username).subscribe({
      next: shares => this.onLoadShares(),
      error: err => console.log(err)
    });
  }

  onCreateShare() {
    this.shareWith = this.shareWith.trim();
    this.tasklistService.addShare(this.currentTasklistId, this.shareWith).subscribe({
      next: shares => this.onLoadShares(),
      error: err => console.log(err)
    });
  }

}
