import {Injectable} from '@angular/core';
import {Person} from '../data-model/person';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API_PREFIX} from '../constants';
import {DataService} from './dataservice';
import {Entity} from '../data-model/entity';

@Injectable({
  providedIn: 'root'
})
export class PersonService implements DataService {

  private serviceURL = API_PREFIX + '/api/v1/person';

  // private serviceURL = 'http://localhost:8181/api/v1/person';


  constructor(private http: HttpClient) {
  }

  get(id: number): Observable<Entity> {
    return this.http.get<Person>(this.serviceURL + '/' + id);
  }

  getAll(): Observable<Entity[]> {
    return this.http.get<Person[]>(this.serviceURL);
  }

  createPerson(person: Person): Observable<Object> {
    return this.http.post(this.serviceURL, person);
  }

  delete(personId: number): Observable<Object> {
    return this.http.delete(this.serviceURL + '/' + personId);
  }

  getPersons(): Observable<Person[]> {
    return this.http.get<Person[]>(this.serviceURL);
  }

  getPerson(id: number): Observable<Person> {
    return this.http.get<Person>(this.serviceURL + '/' + id);
  }

  private handleError(error: HttpErrorResponse) {
    console.error(error);
  }
}
