import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DataService} from './dataservice';
import {PersonService} from './person.service';
import {DataServiceType} from './service.factory.types';
import {DocumentService} from './document.service';
import {ProjectService} from './project.service';
import {ServicetypeService} from './servicetype.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceFactory {

  constructor(private http: HttpClient) {
  }

  get(serviceName: string): DataService {
    if (serviceName === DataServiceType.Person) {
      return new PersonService(this.http);
    } else if (serviceName === DataServiceType.Document) {
      return new DocumentService(this.http);
    } else if (serviceName === DataServiceType.ServiceType) {
      return new ServicetypeService(this.http);
    } else if (serviceName === DataServiceType.Project) {
      return new ProjectService(this.http);
    }else{
      return new PersonService(this.http);
    }
  }
}
