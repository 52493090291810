'use strict';

import {UserService} from './data-services/user.service';
import {BaseDirectory, mkdir, exists, readTextFile, writeTextFile} from '@tauri-apps/plugin-fs';
import {sep} from '@tauri-apps/api/path';
import {EIGHT_HOURS_IN_MILLIS} from './constants';

export function random(from, to) {
  return Math.floor(Math.random() * (to - from + 1) + from);
}

export function showSnackbar(text: string) {
  const snackbar = document.getElementById('snackbar');
  snackbar.innerText = text.trim();
  snackbar.className = 'show';
  setTimeout(() => {
    snackbar.className = snackbar.className.replace('show', '');
  }, 3000);
}

export function showLoading() {
  // maybe we need to count the number of 'show'/'hide' events
  // to keep to loading screen as long as all show/hide pairs got resolved
  const snackbar = document.getElementById('loading-indicator');
  snackbar.className = 'show';
}
export function hideLoading() {
  setTimeout(function(){
    const snackbar = document.getElementById('loading-indicator');
    snackbar.className = snackbar.className.replace('show', '');
  },10);
}

export function isDesktopApp() {
  // @ts-ignore
  return  !!window.__TAURI__;
}

export function tauriInvoke() {
  // @ts-ignore
  return window.__TAURI__.core.invoke;
}

export async function readFile() {
  try {
    let data = await readTextFile("temp" + sep() + "tmp.txt", {baseDir: BaseDirectory.AppData});
    return data;
  } catch (e) {
    return "";
  }
}
export async function readFileWithFilename(filename) {
  try {
    let data = await readTextFile("temp" + sep() + filename, {baseDir: BaseDirectory.AppData});
    return data;
  } catch (e) {
    return "";
  }
}

export async function writeFile(data) {
  let folderExists = await exists('temp', {baseDir: BaseDirectory.AppData});
  if (!folderExists) {
    await mkdir("temp", {baseDir: BaseDirectory.AppData, recursive: true});
  }
  await writeTextFile("temp" + sep() + "tmp.txt", data, {baseDir: BaseDirectory.AppData});
}

export async function writeFileWithFilename(filename, data) {
  if(!isDesktopApp()){
    return;
  }
  let folderExists = await exists('temp', {baseDir: BaseDirectory.AppData});
  if (!folderExists) {
    await mkdir("temp", {baseDir: BaseDirectory.AppData, recursive: true});
  }
  await writeTextFile("temp" + sep() + filename, data, {baseDir: BaseDirectory.AppData});
}

export async function getAccessTokenData(userService: UserService) {
  return new Promise<string>(async (resolve, reject) => { //ASYNC
    if (!isDesktopApp()) {
      return resolve("");
    }

    try {
      let data = await readTextFile("temp"+sep()+"tmp.txt",  { baseDir: BaseDirectory.AppData });
      let tokenWithRefreshToken = JSON.parse(data);
      if (isTokenValid(tokenWithRefreshToken)) { // access token is valid for 8h+
        return resolve(tokenWithRefreshToken.access_token);
      }
      tokenWithRefreshToken = await refreshToken(userService, tokenWithRefreshToken);
      writeFile(JSON.stringify(tokenWithRefreshToken));
      return resolve(tokenWithRefreshToken.access_token);
    } catch (e) {
      console.log("error token fetch " + e);
      return reject("");
    }
  });
}

export async function refreshToken(userService: UserService, tokenWithRefreshToken: object): Promise<object> {
  return new Promise<object>(async (resolve, reject) => { //ASYNC
    if (!isDesktopApp()) {
      return resolve(tokenWithRefreshToken);
    }

    try {
      if (isTokenValid(tokenWithRefreshToken)) { // access token is valid for 8h+
        return resolve(tokenWithRefreshToken);
      }
      // @ts-ignore
      let token = await userService.refreshToken(tokenWithRefreshToken.refresh_token).toPromise();
      // @ts-ignore
      tokenWithRefreshToken.create_date_in_millis = Date.now();
      // @ts-ignore
      tokenWithRefreshToken.access_token = token.access_token;
      if(token.refresh_token){
        // @ts-ignore
        tokenWithRefreshToken.refresh_token = token.refresh_token;
      }
      return resolve(tokenWithRefreshToken);
    } catch (e) {
      console.log("error token fetch " + e);
      return reject("");
    }
  });
}

export function isTokenValid(token){
  // return false;
  return (!!token && !!token.create_date_in_millis && EIGHT_HOURS_IN_MILLIS > (Date.now() - token.create_date_in_millis));
}
