import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API_PREFIX} from '../constants';
import {Eur} from '../data-model/eur';
import {Setting} from '../data-model/setting';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  private serviceURL = API_PREFIX + '/api/v1/setting';

  constructor(private http: HttpClient) {
  }

  save(setting: Setting): Observable<Setting> {
    return this.http.post<Setting>(this.serviceURL, setting);
  }
}
