import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {FormsModule} from '@angular/forms';
import {DatePickerComponent} from './date-picker/date-picker.component';
import localeDE from '@angular/common/locales/de';
import {registerLocaleData} from '@angular/common';
import {LinkPickerComponent} from './link-picker/link-picker.component';
import {TaskComponent} from './task/task.component';
import {TaskOverviewComponent} from './task-overview/task-overview.component';
import {TaskOverviewMenuComponent} from './task-overview-menu/task-overview-menu.component';
import {TaskMenuComponent} from './task-menu/task-menu.component';
import {UnauthorizedComponent} from './unauthorized/unauthorized.component';
import {TasklistaccessComponent} from './tasklistaccess/tasklistaccess.component';
import {ResponseInterceptorInterceptor} from './response-interceptor.interceptor';
import {TokenInterceptor} from './token-interceptor';
import { SearchComponent } from './search/search.component';

registerLocaleData(localeDE);

@NgModule({
  declarations: [
    AppComponent,
    DatePickerComponent,
    LinkPickerComponent,
    TaskComponent,
    TaskOverviewComponent,
    TaskOverviewMenuComponent,
    TaskMenuComponent,
    UnauthorizedComponent,
    TasklistaccessComponent,
    SearchComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptorInterceptor, multi: true }
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
