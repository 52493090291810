'use strict';

import {DragAndDrop} from './data-model/drag_and_drop';
import {HasPosition} from './data-model/has_position';
import {Droppable} from './data-model/droppable';
import {DropEvent} from './data-model/drop_event';

export function setupDragAndDrop(listId: string, callback: DragAndDrop) {
  setTimeout(() => {
    const that = this;
    const settings = {
      connectWith: '.connected-tasklist',
      cancel: 'i,input,.disable-sorting',
      items: 'li:not(.disable-sorting),tr',
      start(event, ui): void {
        ui.item.addClass('dragging');
        ui.item.attr('data-prevIndex', ui.item.index());
        callback.onDrag();
      },
      stop(event, ui): void {
        console.log('DROP ' + ui.item.text() + ' ' + ui.item.attr('class'));
        ui.item.removeClass('dragging');
        callback.onDrop();
      },
      update(event, ui): void {
        const sortWithinList = !ui.sender && this === ui.item.parent()[0];
        const old = ui.item.attr('data-prevIndex');
        // ui.item.removeAttr('data-prevIndex');
        if (!old || !sortWithinList) {
          return;
        }
        // listId
        const result: DropEvent = {
          sourceList: this.id,
          targetList: this.id,
          oldIndex: ui.item.attr('data-prevIndex'),
          newIndex: ui.item.index(),
          draggedElement: ui.item
        };
        ui.item.removeAttr('data-prevIndex');
        callback.onUpdate(result);
        // that.updatePositions(old, ui.item.index());
      },
      receive(event, ui) {
        const result: DropEvent = {
          sourceList: ui.sender[0].id,
          targetList: this.id,
          oldIndex: ui.item.attr('data-prevIndex'),
          newIndex: ui.item.index(),
          draggedElement: ui.item
        };

        // console.log('item index = ' + ui.item.attr('data-prevIndex')); // Which item (or ui.item[0].id)
        // console.log('dropped on = ' + this.id); // Where the item is dropped
        // console.log('sender = ' + ui.sender[0].id); // Where it came from
        // console.log('item = ' + ui.item[0].innerHTML); // Which item (or ui.item[0].id)
        ui.item.removeAttr('data-prevIndex');
        callback.onUpdate(result);
      }
    };

    // @ts-ignore
    $('#' + listId).sortable(settings);
    // @ts-ignore
    $('#' + listId).disableSelection();
  }, 1);
}

export function setupDropable(callback: Droppable) {
  setTimeout(() => {
    // tasklists-item taskrow
    const that = this;
    console.log('attach droppable');
    // @ts-ignore
    $('.tasklists-item').droppable({
      accept: '.taskrow',
      tolerance: 'pointer',
      hoverClass: 'ui-state-hover',
      drop(event, ui) {
        const dropped = ui.draggable;
        // @ts-ignore
        const droppedOn = $(this);
        callback.onDropped(droppedOn.attr('data-tasklist'), dropped.attr('data-prevIndex'), dropped.attr('data-tasklist'));
      }
    });
  }, 500);
}

export function getListForPositionUpdate(entities: HasPosition[]): HasPosition[] {
  const positions = new Array<HasPosition>(entities.length);
  for (let i = 0; i < entities.length; i++) {
    positions[i] = {id: entities[i].id, position: entities[i].position};
  }
  return positions;
}
