import {Component, ComponentFactoryResolver} from '@angular/core';
import {EventBusService} from '../event-bus.service';
import {ActivatedRoute, ChildrenOutletContexts, Router} from '@angular/router';
import {UserService} from '../data-services/user.service';
import {Location} from '@angular/common';
import {TaskService} from '../data-services/task.service';
import {EventbusEvent} from '../data-model/eventbus_event';
import {EventbusEventType} from '../data-model/eventbus_event_type';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent {
  selectedResult = -1;
  query: string;
  isPreview: boolean;
  results = [];
  // results = [
  //   {title: "result", link: "OK"},
  //   {title: "result1", link: "OK"},
  //   {title: "result2", link: "OK"}
  // ];
  pendingNavigation: number;
  pendingSearch: number;
  subscription;

  constructor(private eventBus: EventBusService, private router: Router, public userService: UserService,
              private activatedRoute: ActivatedRoute,
              private componentFactoryResolver: ComponentFactoryResolver,
              private contexts: ChildrenOutletContexts,
              private location: Location,
              private taskService: TaskService) {
  }

  ngOnInit(){
    this.activatedRoute.queryParams.subscribe(params => {
      this.isPreview = (params.preview ? true : false);
      console.log("is preview: " +this.isPreview);
    });

    this.subscription = this.eventBus.eventFired$.subscribe({
      next: event => this.handleAction(event)
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onCloseSearch(){
    this.clearSearch();
    this.closePreview();
    this.hideSearch();
  }

  hideSearch(){
    console.log("Hiding...");
    this.eventBus.emitEvent({action: EventbusEventType.HIDE_SEARCH});
  }

  clearSearch(){
    this.query = '';
    this.results = [];
    this.selectedResult = -1;
    // setTimeout(() => {
    //   const element = document.getElementById("search-input");
    //   element.blur();
    // }, 1);
  }

  onNextResult(){
    this.selectedResult++;

    if (this.selectedResult > this.results.length - 1) {
      this.selectedResult = this.results.length - 1;
    }
    this.onPreviewResult(this.selectedResult);
  }
  onPreviousResult(){
    this.selectedResult--;
    if(this.selectedResult<0){
      this.selectedResult= -1;
      document.getElementById("search-input").focus();
      this.closePreview();
      return;
    }
    this.onPreviewResult(this.selectedResult);
  }

  onSearch(){
    if(this.pendingSearch){
      clearTimeout(this.pendingSearch);
      this.pendingSearch = undefined;
    }

    if(!this.query){
      this.results = [];
      return;
    }

    this.pendingSearch = setTimeout(() => {
      this.pendingSearch = undefined;
      console.log("start search");

      this.taskService.findTasks(this.query).subscribe({
        next: tasks => {
          this.results = [];
          for(let task of tasks){
            this.results.push({title: task.title, link: 'task/' +task.id});
          }
          console.log(JSON.stringify(tasks));
        },
        error: err => console.log(err)
      });

    }, 500);
  }

  onPreviewResult(i: number){
    let link = this.results[i].link;
    this.selectedResult = i;
    if(this.pendingNavigation){
      clearTimeout(this.pendingNavigation);
      this.pendingNavigation = undefined;
    }

    this.pendingNavigation = setTimeout(() => {
      // document.getElementById("search-results").focus();
      // document.getElementById("search-input").blur();
      this.pendingNavigation = undefined;
      console.log("Selected " + link);
      let parameter = {
        preview: true
      }
      this.router.navigate([link], { replaceUrl: this.isPreview, queryParams: parameter });
    }, 100);
  }

  onEnter(){
    console.log("onEnter");
    if(this.results &&  this.results[this.selectedResult]){
      this.onSelect(this.results[this.selectedResult].link);
    }
  }

  onSelect(link){
    console.log("selecting: " + JSON.stringify(link));
    this.router.navigate([link], { replaceUrl: true });
    this.clearSearch();
    this.hideSearch();
  }


  handleAction(event: EventbusEvent): void {
    if (event.action === EventbusEventType.SHOW_SEARCH) {
      setTimeout(() => {
        const element = document.getElementById("search-input");
        element.focus();
      }, 1);
    }
  }

  closePreview(){
    console.log("leaving...");
    if(this.pendingNavigation){
      clearTimeout(this.pendingNavigation);
      this.pendingNavigation = undefined;
    }

    // setTimeout(() => {
      if(this.isPreview){
        this.location.back();
      }
    // }, 2000);
  }

}
