import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  OnDestroy,
  OnInit,
  QueryList,
  Type,
  ViewChild,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';
import {ActivatedRoute, ChildrenOutletContexts, NavigationEnd, Router} from '@angular/router';
import {UserService} from './data-services/user.service';
import {User} from './data-model/user';
import {Subscription} from 'rxjs';
import {EventBusService} from './event-bus.service';
import {slideInAnimation} from './animations';
import {Location} from '@angular/common';
import {ModalQuestion} from './data-model/modal_question';
import {EventbusEvent} from './data-model/eventbus_event';
import {EventbusEventType} from './data-model/eventbus_event_type';
import {EventBusResult} from './data-model/evebt_bus_result';
import {isDesktopApp} from './utility';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    slideInAnimation
  ]
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {
  subscription: Subscription;
  headerTitle: string;
  showHeader: boolean;
  showSidebar: boolean;
  showBreadcrumb: boolean;
  user: User;
  title = 'Sandbox';

  decisionModalModel: ModalQuestion = {
    focusOnPrimary: false, cancelText: '', confirmClass: '', confirmText: '', message: '', title: ''
  };

  @ViewChild('popupContainer', {read: ViewContainerRef})
  popupContainer: ViewContainerRef;

  // Keep track of list of generated components for removal purposes
  // components = [];
  componentClass: Type<any>;
  // https://stackoverflow.com/questions/34947154/angular-2-viewchild-annotation-returns-undefined#comment75012323_36488227
  @ViewChildren('container', {read: ViewContainerRef})
  container: QueryList<Type<any>>;
  private cont;

  constructor(private eventBus: EventBusService, private router: Router, public userService: UserService,
              private activatedRoute: ActivatedRoute,
              private componentFactoryResolver: ComponentFactoryResolver,
              private contexts: ChildrenOutletContexts,
              private location: Location) {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getBackButtonVisibility() {
    if(this.getRouteAnimationData() == '1'){
      return 'hidden';
    }
    return 'visible';
  }

  getRouteAnimationData() {
    // https://angular.io/guide/route-animations
    return this.contexts.getContext('primary')?.route?.snapshot?.data.navigationlevel;
  }

  onBack() {
    // if (this.activatedRoute.firstChild.snapshot.data.navigationlevel === '2') {
    //   this.router.navigate(['tasks']);
    //   return;
    // }
    this.location.back();
  }

  public ngAfterViewInit(): void {
    this.container.changes.subscribe((comps: QueryList<Type<any>>) => {
      this.cont = comps.first;
      // this.setupActions(this.componentClass);
    });
  }

  setupActions(componentClass: Type<any>): void {
    setTimeout(() => {
      if (this.cont) {
        // Create component dynamically inside the ng-template
        this.cont.clear();

        if (!componentClass) {
          return;
        }

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentClass);
        this.cont.createComponent(componentFactory);
        // const component = this.cont.createComponent(componentFactory);
        // https://stackoverflow.com/questions/42387348/angular2-dynamic-content-loading-throws-expression-changed-exception
        // component.changeDetectorRef.detectChanges();
        // Push the component so that we can keep track of which components are created
        // this.components.push(component);
      }
    }, 1);
    // this.components = [];

  }

  showPopupForComponent(component: any): void {
    // console.log('show popup component ' + component);
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    this.popupContainer.clear();
    this.popupContainer.createComponent(componentFactory);

    // @ts-ignore
    $('#exampleModal').on('hide.bs.modal', () => {
      // put your default event here
      console.log('modal closed');
    }).modal({
      // backdrop: 'static',
      // keyboard: false
    });
  }

  handleAction(event: EventbusEvent): void {
    // console.log('show popup component ' + event);
    // if (true) {
    //   this.showPopupForComponent(TaskOverviewComponent);
    // }
    if (event.action === EventbusEventType.MODAL_QUESTION) {
      this.decisionModalModel = event.payload as ModalQuestion;

      setTimeout((() => {
        // @ts-ignore
        $('#decisionModal').on('shown.bs.modal', () => {
          if (this.decisionModalModel.focusOnPrimary) {
            // @ts-ignore
            $('#questionModalConfirm').trigger('focus');
          } else {
            // @ts-ignore
            $('#questionModalCancel').trigger('focus');
          }
        }).modal();
      }), 0);
      return;
    } else if (event.action === EventbusEventType.MODAL_INFO) {
      this.decisionModalModel = event.payload as ModalQuestion;
      // @ts-ignore
      $('#decisionModal').on('shown.bs.modal', () => {
        if (this.decisionModalModel.focusOnPrimary) {
          // @ts-ignore
          $('#questionModalConfirm').trigger('focus');
        } else {
          // @ts-ignore
          $('#questionModalCancel').trigger('focus');
        }
      }).modal();
      return;
    } else if (event.action === EventbusEventType.SHOW_SEARCH) {
      let element = document.getElementById("search-popup");
      element.style.display = "block";
      return;
    } else if (event.action === EventbusEventType.HIDE_SEARCH) {
      let element = document.getElementById("search-popup");
      element.style.display = "none";
      return;
    } else if (event.action === EventbusEventType.CONTROL_E_PRESSED) {
      this.eventBus.emitEvent({action: EventbusEventType.SHOW_SEARCH});
      return;
    }
  }

  onSelectSearchResult(link: string){
    console.log("Selected " + link);
    if(link === "enter"){
      console.log("Store current route")
      return;
    }else if(link === "leave"){
      console.log("Restore route");
      this.router.navigate(['tasks']);
      return;
    }
    this.router.navigate(['task']);
  }

  onConfirmQuestion(result: EventBusResult) {
    this.eventBus.emitEvent({action: EventbusEventType.MODAL_RESULT, payload: result});
    // @ts-ignore
    $('#decisionModal').modal('hide');

  }

  async registerShortcuts(){
    if (isDesktopApp()) {
      let that = this;
      window.onkeyup = (e) => {

        console.log(e.code + " (" + e.ctrlKey + ", " + e.shiftKey + ")");
        if (e.code === 'F2') {
          this.eventBus.emitEvent({action: EventbusEventType.F2_PRESSED});
        }

        if (e.ctrlKey) {
          if (e.code === 'KeyN') {
            if (e.shiftKey) {
              that.eventBus.emitEvent({action: EventbusEventType.CONTROL_SHIFT_N_PRESSED});
            } else {
              that.eventBus.emitEvent({action: EventbusEventType.CONTROL_N_PRESSED});
            }
          } else if (e.code === 'KeyE') {
            that.eventBus.emitEvent({action: EventbusEventType.CONTROL_E_PRESSED});
          } else if (e.code === 'KeyS') {
            that.eventBus.emitEvent({action: EventbusEventType.CONTROL_S_PRESSED});
          } else if (e.code === 'KeyT') {
            that.eventBus.emitEvent({action: EventbusEventType.CONTROL_T_PRESSED});
          }
        }
      }
    }
    // if(isDesktopApp()){
    //    let shortcuts = [
    //      {shortcut: 'CommandOrControl+N', value: EventbusEventType.CONTROL_N_PRESSED},
    //      {shortcut: 'CommandOrControl+S', value: EventbusEventType.CONTROL_S_PRESSED},
    //      {shortcut: 'CommandOrControl+E', value: EventbusEventType.CONTROL_E_PRESSED},
    //      {shortcut: 'CommandOrControl+T', value: EventbusEventType.CONTROL_T_PRESSED},
    //      {shortcut: 'F2', value: EventbusEventType.F2_PRESSED}
    //    ];
    //
    //   for (let shortcut of shortcuts) {
    //     if (!await isRegistered(shortcut.shortcut)) {
    //       register(shortcut.shortcut, () => {
    //         this.eventBus.emitEvent({action: shortcut.value});
    //       });
    //     }
    //   }
    // }
  }

  ngOnInit(): void {
    this.subscription = this.eventBus.eventFired$.subscribe({
      next: event => this.handleAction(event)
    });

    this.registerShortcuts();

    let that = this;
    setTimeout(async function () {
      // if(isDesktopApp() && !BEARER_TOKEN.access_token){
      //   try{
      //     let token = await getAccessTokenData(userService);
      //     BEARER_TOKEN.access_token = token;
      //     console.log("token fetched");
      //   }catch (e) {
      //     console.log("error token fetched");
      //   }
      // }

      that.userService.getUsername().subscribe({
        next: user => that.user = user,
        error: err => console.log(err)
      });
    }, 500);


    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.headerTitle = this.activatedRoute.firstChild.snapshot.data.headerTitle;
        this.showHeader = this.activatedRoute.firstChild.snapshot.data.showHeader !== false;
        this.showSidebar = this.activatedRoute.firstChild.snapshot.data.showSidebar !== false;
        this.showBreadcrumb = this.activatedRoute.firstChild.snapshot.data.showBreadcrumb === true;
        this.componentClass = this.activatedRoute.firstChild.snapshot.data.menu;
        this.setupActions(this.componentClass);
        // this.setupActions(this.activatedRoute.firstChild.snapshot.data.menu);
      }

    });
  }
}
