import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Document} from '../data-model/document';
import {API_PREFIX} from '../constants';
import {DataService} from './dataservice';
import {Entity} from '../data-model/entity';

@Injectable({
  providedIn: 'root'
})
export class DocumentService implements DataService{

  private serviceURL = API_PREFIX + '/api/v1/document';

  constructor(private http: HttpClient) {
  }

  get(id: number): Observable<Entity> {
    return this.http.get<Document>(this.serviceURL + '/' + id);
  }

  getAll(): Observable<Entity[]> {
    return this.http.get<Document[]>(this.serviceURL, {
      params: {
        type: 'invoice'
      }
    });
  }

  create(document: Document): Observable<Document> {
    return this.http.post<Document>(this.serviceURL, document);
  }

  update(document: Document): Observable<Document> {
    return this.http.put<Document>(this.serviceURL + '/' + document.id, document);
  }

  immutable(document: Document): Observable<Document> {
    return this.http.post<Document>(this.serviceURL + '/' + document.id + '/immutable', '');
  }
  unmute(document: Document): Observable<Document> {
    return this.http.post<Document>(this.serviceURL + '/' + document.id + '/unmute', '');
  }

  payment(document: Document): Observable<Document> {
    return this.http.post<Document>(this.serviceURL + '/' + document.id + '/payment', '');
  }

  delete(documentId: number): Observable<void> {
    return this.http.delete<void>(this.serviceURL + '/' + documentId);
  }

  getInvoices(): Observable<Document[]> {
    return this.http.get<Document[]>(this.serviceURL, {
      params: {
        type: 'invoice'
      }
    });
  }

  getReceipts(): Observable<Document[]> {
    return this.http.get<Document[]>(this.serviceURL, {
      params: {
        type: 'receipt'
      }
    });
  }

  getDocument(id: number): Observable<Document> {
    return this.http.get<Document>(this.serviceURL + '/' + id);
  }
}
