import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {UserService} from './data-services/user.service';
import {User} from './data-model/user';
import {getAccessTokenData, isDesktopApp, isTokenValid, refreshToken} from './utility';
import {BEARER_TOKEN, ROLES_CHECK} from './constants';

@Injectable({
  providedIn: 'root'
})
export class RolesGuard {
  user: User;

  constructor(protected router: Router, protected userService: UserService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> | boolean | UrlTree {

    if (ROLES_CHECK.successful !== undefined) {
      return this.getGuardResult();
    }
    ROLES_CHECK.successful = false;
    return this.checkRoles();
  }

  async checkRoles(): Promise<boolean | UrlTree> {
    return new Promise<any>(async (resolve, reject) => { //ASYNC

      console.error("Checking roles");
      if (isDesktopApp()) {
        if (!BEARER_TOKEN.access_token) {
          try {
            console.error("Checking roles (getAccessTokenData)");
            BEARER_TOKEN.access_token = await getAccessTokenData(this.userService);
          } catch (e) {
            console.log("error token fetched. " + e);
          }
        }
      }

      try {
        this.userService.getUsername().subscribe({
          next: result => {
            this.user = result
            if (this.user && this.user.isAuthenticated) {
              for (const role of this.user.roles) {
                if (role.toLowerCase() === 'role_read_permission') {
                  ROLES_CHECK.successful = true;
                  break;
                }
              }
            }
            resolve(this.getGuardResult());
          },
          error: err => {
            console.log(err);
            resolve(this.getGuardResult());
          }
        });
      } catch (e) {
        resolve(this.getGuardResult());
      }
    });
  }

  getGuardResult() {
    if (ROLES_CHECK.successful) {
      return true;
    }
    if (this.user && this.user.isAuthenticated && !!this.user.name) {
      return this.router.parseUrl('/unauthorized?username=' + this.user.name);
    }
    return this.router.parseUrl('/unauthorized');
  }
}

