<div class="my-3 p-3 bg-white rounded shadow-sm">

  <div>
    <div class="container">
      <div class="row">
        <div class="col-sm">
          <div class="form-group">
            <label>Titel</label>
            <input [(ngModel)]="task.title" id="tasktitle" class="form-control form-control-sm" autofocus
                   (keydown.enter)="save()"/>
          </div>
        </div>

        <div class="col-auto" style="width: 8rem">
          <div>
            <div class="form-group">
              <label>Datum</label>
              <app-date-picker [(value)]="task.dueDate"></app-date-picker>
            </div>
          </div>
        </div>

        <div class="col-auto" style="width: 2rem; margin-left: -30px">
          <div>
            <div class="form-group">
              <label style="opacity: 0">Wiederholung</label>
              <i [hidden]="!isRecurring" [style.border]="isRecurring?'1px solid back':'0'" (click)="showRecurring()"
                 class="btn btn-link bi bi-arrow-repeat"></i>
              <div [hidden]="isRecurring" (click)="showRecurring()" style="font-size: 0.9rem" class="btn btn-link">
                Wdhlg.
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto" style="width: 8rem; margin-left: 3rem;">
          <div>
            <div>
              <div class="form-group">
                <label style="opacity: 0">Erledigt</label>
                <div>
                  <button *ngIf="task.id && !task.completionDate &&!navigateToOverview" style="float: right; width: 100%"
                          class="btn btn-success btn-sm"
                          (click)="onCompleted()" [disabled]="!task.id">
                    <b>Erledigt</b></button>

                  <button *ngIf="task.completionDate &&task.completionDate && !navigateToOverview" style="float: right"
                          class="btn btn-link btn-sm" (click)="onCompleted()">
                    <b>Nicht erledigt</b></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm">

          <div class="form-group">
        <textarea id='task-description' [rows]="numberOfRows" [(ngModel)]="task.description" class="form-control"
                  style="width: 100%; min-height: 400px;"></textarea>
          </div>
        </div>
      </div>

      <div style="float: right; margin-top: 0.6rem; padding-bottom: 0.6rem">
        <button type="button" (click)="onCreateTag()" class="tag btn btn-link btn-sm">Neues Tag…</button>
        <button type="button" (click)="onRemoveTag(tag)" *ngFor="let tag of task.tags"
                class="tag btn btn-primary btn-sm">{{tag.name}} <i
          class="bi bi-x"></i></button>
        <button type="button" (click)="onAddTag(tag)" *ngFor="let tag of tags"
                class="tag btn btn-light btn-sm">{{tag.name}}</button>
      </div>
    </div>

  </div>
  <br/>

</div>


<div id="tagModal" class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Neues Tag erstellen</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div class="container">
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label>Tag Name</label>
                <input id="taginput" [(ngModel)]="newTagname" class="form-control form-control-sm" autofocus
                       (keydown.enter)="onCreateTagConfirmed()"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-dismiss="modal">Abbrechen</button>
        <button id="createTag" (click)="onCreateTagConfirmed()" type="button" class="btn btn-primary">Erstellen</button>
      </div>
    </div>
  </div>
</div>


<div id="recurringModal" class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Wiederholen</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <!--<div class="form-check form-check-inline">-->
        <!--  <input class="form-check-input" id="isrecurring" type="checkbox" [checked]="isRecurring" (change)="onRecurringChanged()">-->
        <!--  <label class="form-check-label" for="isrecurring">Repeat</label>-->
        <!--</div>-->

        <div>

          <div>
            <div>Wiederholung alle:</div>
            <input id="repeat-main" [(ngModel)]="recurrence.every" style="width: 6rem; margin-right: 0.3rem;">

            <select name="repeat" id="repeat" [(ngModel)]="recurrence.type">
              <option value="0" [selected]="recurrence.type==0">Tag</option>
              <option value="1" [selected]="recurrence.type==1">Woche</option>
              <option value="2" [selected]="recurrence.type==2">Monat</option>
              <option value="3" [selected]="recurrence.type==3">Jahr</option>
            </select></div>
          <br/>

          <div [hidden]="recurrence.type != 2">
            <input type="radio" id="Day" [checked]="monthRepeatOption==0" (change)="onRadioMonthRepeatChanged(0)"
                   name="month_radio">
            <select style="margin-left: 0.3rem;" name="month_day" id="month_day" [(ngModel)]="daysOfSpecificMonthDay">
              <option value="1" selected="selected">1. Tag</option>
              <option value="2">2. Tag</option>
              <option value="3">3. Tag</option>
              <option value="4">4. Tag</option>
              <option value="5">5. Tag</option>
              <option value="6">6. Tag</option>
              <option value="7">7. Tag</option>
              <option value="8">8. Tag</option>
              <option value="9">9. Tag</option>
              <option value="10">10. Tag</option>
              <option value="11">11. Tag</option>
              <option value="12">12. Tag</option>
              <option value="13">13. Tag</option>
              <option value="14">14. Tag</option>
              <option value="15">15. Tag</option>
              <option value="16">16. Tag</option>
              <option value="17">17. Tag</option>
              <option value="18">18. Tag</option>
              <option value="19">19. Tag</option>
              <option value="20">20. Tag</option>
              <option value="21">21. Tag</option>
              <option value="22">22. Tag</option>
              <option value="23">23. Tag</option>
              <option value="24">24. Tag</option>
              <option value="25">25. Tag</option>
              <option value="26">26. Tag</option>
              <option value="27">27. Tag</option>
              <option value="28">28. Tag</option>
              <option value="29">29. Tag</option>
              <option value="30">30. Tag</option>
              <option value="31">31. Tag</option>
              <option value="32">Last Tag</option>
            </select>
            <div style="margin-top: 0.3rem;">
              <input type="radio" name="month_radio" [checked]="monthRepeatOption==1"
                     (change)="onRadioMonthRepeatChanged(1)">
              <select style="margin-left: 0.3rem;" name="day_occurrence" id="day_occurrence" [(ngModel)]="monthEvery">
                <option value="1" [selected]="monthEvery==1">Erster</option>
                <option value="2" [selected]="monthEvery==2">Zweiter</option>
                <option value="3" [selected]="monthEvery==3">Dritter</option>
                <option value="4" [selected]="monthEvery==4">Vierter</option>
                <option value="5" [selected]="monthEvery==5">Letzter</option>
              </select>

              <select style="margin-left: 0.3rem;" name="weekday" id="weekday" [(ngModel)]="daysOfMonthEvery">
                <option value="2" [selected]="daysOfMonthEvery==2">Montag</option>
                <option value="3" [selected]="daysOfMonthEvery==3">Dienstag</option>
                <option value="4" [selected]="daysOfMonthEvery==4">Mittwoch</option>
                <option value="5" [selected]="daysOfMonthEvery==5">Donnerstag</option>
                <option value="6" [selected]="daysOfMonthEvery==6">Freitag</option>
                <option value="7" [selected]="daysOfMonthEvery==7">Samstag</option>
                <option value="1" [selected]="daysOfMonthEvery==1">Sonntag</option>
              </select>
            </div>
            <br>
          </div>

          <div [hidden]="recurrence.type != 1">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="monday" [checked]="weekdays[1]"
                     (change)="onWeekdayCheckboxChanged(1)">
              <label class="form-check-label" for="monday">Mo</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="tuesday" [checked]="weekdays[2]"
                     (change)="onWeekdayCheckboxChanged(2)">
              <label class="form-check-label" for="tuesday">Di</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="wednesday" [checked]="weekdays[3]"
                     (change)="onWeekdayCheckboxChanged(3)">
              <label class="form-check-label" for="wednesday">Mi</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="thursday" [checked]="weekdays[4]"
                     (change)="onWeekdayCheckboxChanged(4)">
              <label class="form-check-label" for="thursday">Do</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="friday" [checked]="weekdays[5]"
                     (change)="onWeekdayCheckboxChanged(5)">
              <label class="form-check-label" for="friday">Fr</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="saturday" [checked]="weekdays[6]"
                     (change)="onWeekdayCheckboxChanged(6)">
              <label class="form-check-label" for="saturday">Sa</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="sunday" [checked]="weekdays[0]"
                     (change)="onWeekdayCheckboxChanged(0)">
              <label class="form-check-label" for="sunday">So</label>
            </div>
            <br>
            <br>
          </div>

          <div>Start</div>
          <app-date-picker [(value)]="recurrence.startDate"
                           style="display:inline-block; width: 6rem;"></app-date-picker>
          <br>

          <br/>
          <div>Endet</div>
          <input type="radio" id="never" [checked]="endsOption==0" name="ends_after"
                 (change)="onRadioEndOptionsChanged(0)">
          <label style="margin-left:0.3rem;" for="Never">Niemals</label><br>
          <input type="radio" id="on" name="ends_after" [checked]="endsOption==1"
                 (change)="onRadioEndOptionsChanged(1)">
          <label style="margin-left:0.3rem;" for="On">Am</label>
          <app-date-picker style="margin-left: 1rem; display:inline-block; width: 6rem;"
                           [(value)]="recurrence.endDate"></app-date-picker>
          <br>
          <input type="radio" id="after" name="ends_after" [checked]="endsOption==2"
                 (change)="onRadioEndOptionsChanged(2)">
          <label style="margin-left:0.3rem;" for="After">Nach</label>
          <input id="occurrences" style="margin-left: 1rem; display:inline-block; width: 6rem;"
                 [(ngModel)]="recurrence.maxOccurrences" class="form-control form-control-sm">
        </div>

      </div>
      <div class="modal-footer">
        <button (click)="cancelRecurring()" type="button" class="btn btn-light" data-dismiss="modal">Entfernen</button>
        <button (click)="confirmRecurring()" type="button" class="btn btn-primary">Ok</button>
      </div>
    </div>
  </div>
</div>

<!-- Button trigger modal -->
<!--<button (click)="setupPopup()" type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">-->
<!--  Launch demo modal-->
<!--</button>-->
<!-- Button trigger modal -->
<!--<button (click)="setupPopup()" type="button" class="btn btn-primary">-->
<!--  Launch demo modal-->
<!--</button>-->
