import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API_PREFIX} from '../constants';
import {Task} from '../data-model/task';
import {HasPosition} from '../data-model/has_position';
import {Tasklist} from '../data-model/tasklist';
import {DropPayload} from '../data-model/drop_payload';
import {EditManyTasks} from '../data-model/editManyTasks';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  private serviceURL = API_PREFIX + '/api/v1/task';

  constructor(private http: HttpClient) {
  }

  createMany(editManyTasks: EditManyTasks): Observable<Task> {
    return this.http.post<Task>(this.serviceURL + '/many', editManyTasks);
  }

  updateMany(editManyTasks: EditManyTasks): Observable<Task> {
    return this.http.put<Task>(this.serviceURL + '/many', editManyTasks);
  }

  patch(id: string, patch: any): Observable<Task> {
    return this.http.patch<Task>(this.serviceURL + '/' + id, patch);
  }

  update(task: Task): Observable<Task> {
    return this.http.post<Task>(this.serviceURL, task);
  }

  updatePositions(tasks: HasPosition[]): Observable<Task> {
    return this.http.post<Task>(this.serviceURL + '/positions', tasks);
  }

  updatePosition(payload: DropPayload): Observable<Task> {
    return this.http.post<Task>(this.serviceURL + '/move', payload);
  }

  delete(id: string): Observable<Task> {
    return this.http.delete<Task>(this.serviceURL + '/' + id);
  }

  getTasks(): Observable<Task[]> {
    return this.http.get<Task[]>(this.serviceURL);
  }
  findTasks(query: string): Observable<Task[]> {
    let parameter = { query: query};
    return this.http.get<Task[]>(this.serviceURL + "/find", {
      params: parameter
    });
  }

  getTasksByTasklist(tasklist: Tasklist, sort: string, tags: string[]): Observable<Task[]> {
    // https://stackoverflow.com/questions/12710905/how-do-i-dynamically-assign-properties-to-an-object-in-typescript
    const parameter: { [k: string]: any } = {};

    if (tasklist.id && tasklist.id.length > 0) {
      parameter.tasklist = String(tasklist.id);
    }

    for (const prop in tasklist.filter) {
      if (Object.prototype.hasOwnProperty.call(tasklist.filter, prop)) {
        if (!!tasklist.filter[prop]) {
          parameter[prop] = tasklist.filter[prop];
        }
      }
    }

    if (sort && sort.length > 0) {
      parameter.sort = sort;
    }

    if (tags && tags.length > 0) {
      parameter.tag = tags;
    }

    // if (tasklistId != null) {
    return this.http.get<Task[]>(this.serviceURL, {
      params: parameter
    });
    // } else {
    //   return this.http.get<Task[]>(this.serviceURL);
    // }
  }

  get(id: string): Observable<Task> {
    return this.http.get<Task>(this.serviceURL + '/' + id);
  }

  private handleError(error: HttpErrorResponse) {
    console.error(error);
  }
}
