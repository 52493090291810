import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {BEARER_TOKEN} from './constants';
import {hideLoading, showLoading, showSnackbar} from './utility';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  timeoutRequest: number;
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(this.timeoutRequest){
      clearTimeout(this.timeoutRequest);
    }

    if (req.url && req.url.endsWith("/oauth2/token")) {
      return next.handle(req);
    }

    this.timeoutRequest = setTimeout(function(){
      showLoading();
    },1000);

    let theRequest = req;
    if (BEARER_TOKEN.access_token) {
      theRequest = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${BEARER_TOKEN.access_token}`),
      });
    }

    return next.handle(theRequest)
      .pipe(catchError((err) => {
        clearTimeout(this.timeoutRequest);
        hideLoading();
      return err;
    }))
      .pipe(map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
        if (evt instanceof HttpResponse) {
          clearTimeout(this.timeoutRequest);
          hideLoading();
        }
        return evt;
      }));
  }
}
