<p *ngIf="!shares">Keine Berechtigungen erteilt</p>
<div *ngIf="shares">

  <input [(ngModel)]="shareWith" type="text" class="form-control" placeholder="Enter email or username" (keydown.enter)="onCreateShare()">
  <ul>
    <li *ngFor="let share of shares">{{share.alias}} <button (click)="onDeleteShare(share.username)">Delete</button></li>
  </ul>


</div>
