import {AfterViewInit, Component, ComponentFactoryResolver, OnDestroy, OnInit} from '@angular/core';
import {Task} from '../data-model/task';
import {EventBusService} from '../event-bus.service';
import {Subscription} from 'rxjs';
import {TaskService} from '../data-services/task.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Tasklist} from '../data-model/tasklist';
import {Tag} from '../data-model/tag';
import {TagService} from '../data-services/tag.service';
import {Recurrence} from '../data-model/recurrence';
import {showSnackbar} from '../utility';
import {TasklistService} from '../data-services/tasklist.service';

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.css']
})
export class TaskComponent implements OnInit, OnDestroy, AfterViewInit {
  subscription: Subscription;
  selectedId: string;
  navigateToOverview = false;
  tasklistId = null;
  filter = null;
  numberOfRows = 5;
  tasklist: Tasklist;
  tagsForTask: string[];
  tags: Tag[];
  newTagname: string;
  task: Task = {
    version: 0,
    id: null, title: 'Task1', description: '', dueDate: null, reminderDate: null, completionDate: null, position: 0,
    tasklist: null, tags: null
  };
  recurrence: Recurrence = {
    days: '1', endDate: new Date(), every: 1, id: 0, maxOccurrences: 10, monthEvery: 1, startDate: new Date(), type: 1
  };
  weekdays: boolean[] = new Array<boolean>(7); // SUN - SAT
  endsOption = 0;
  monthRepeatOption = 0;
  isRecurring = false;
  daysOfMonthEvery = 2;
  daysOfSpecificMonthDay = 1;
  monthEvery = 1;
  isPreview= false;

  ngAfterViewInit() {
    console.log('after view checked');
    if(!this.isPreview){
      document.getElementById('tasktitle').focus();
    }
  }

  constructor(private eventBus: EventBusService,
              private componentFactoryResolver: ComponentFactoryResolver,
              private taskService: TaskService, private tasklistService: TasklistService, private tagService: TagService,
              protected route: ActivatedRoute, protected router: Router) {
  }

  popupClosed(result: string): void {
    console.log('popup closed');
  }

  onWeekdayCheckboxChanged(index: number) {
    this.weekdays[index] = !this.weekdays[index];
  }

  onTypeChanged(type: number) {
    this.recurrence.type = type;
  }

  onRecurringChanged() {
    this.isRecurring = !this.isRecurring;
  }

  onRadioEndOptionsChanged(value: number) {
    this.endsOption = value;
  }

  onRadioMonthRepeatChanged(value: number) {
    this.monthRepeatOption = value;
  }

  setupPopup(): void {
    // this.eventBus.emitEvent(TimeregistrationComponent);
  }

  ngOnInit(): void {
    const currentDay = new Date().getDay();
    this.weekdays[currentDay] = true;
    // this.subscription = this.eventBus.popupClosed$.subscribe({
    //   next: result => this.popupClosed(result)
    // });
    this.subscription = this.eventBus.menuActionChange$.subscribe({
      next: action => this.handleAction(action)
    });

    this.route.queryParams.subscribe(params => {
      this.isPreview = params.preview;
      if (!!params.tag) {
        if (typeof params.tag === 'string' || params.tag instanceof String) {
          // @ts-ignore
          this.tagsForTask = [params.tag];
        } else if (Array.isArray(params.tag)) {
          this.tagsForTask = params.tag;
        }
      }

      this.tasklistId = params.tasklist;
      this.filter = params.filter;
      let tasklistIdForNewTask = this.tasklistId;
      if (tasklistIdForNewTask <= 0) {
        tasklistIdForNewTask = undefined;
      }
      this.tasklist = {
        name: '',
        parent: undefined,
        position: 0,
        type: 0,
        version: 0,
        id: tasklistIdForNewTask,
        numberOfTasks: 0,
        isOwner: true,
        isShared: false,
        filter: null
      };
    });
    this.route.params.subscribe(params => {
      this.selectedId = params.id;

      if (this.selectedId == null) {
        // this.newEntry();
        this.task = {
          id: null,
          version: 0,
          title: '',
          description: '',
          dueDate: null,
          reminderDate: null,
          completionDate: null,
          position: 0,
          tasklist: this.tasklist,
          tags: []
        };
        this.eventBus.emitDataLoaded(this.task);
        return;
      }

      this.taskService.get(this.selectedId).subscribe({
        next: documents => this.onLoadSuccess(documents),
        error: err => this.onLoadFail(err)
      });
    });


    this.tagService.getTags().subscribe({
      next: tags => {
        this.tags = tags;
        const tags2Add = [];
        if (this.tagsForTask && this.tagsForTask.length > 0) {
          for (let i = 0; i < this.tags.length; i++) {
            for (let j = 0; j < this.tagsForTask.length; j++) {
              if (this.tags[i].name === this.tagsForTask[j]) {
                tags2Add.push(this.tags[i]);
              }
            }
          }
          for (let i = 0; i < tags2Add.length; i++) {
            this.onAddTag(tags2Add[i]);
          }
        }
        // for (tag : this.tagsForTask){
        //   this.task.tags
        // }
      },
      error: error => console.log(error)
    });
    // setTimeout(() => {
    //   const that = this;
    //   // @ts-ignore
    //   $('#calendar').datepicker({
    //     dateFormat: 'dd.mm.yy',
    //     onSelect: date => {
    //       const parts = date.split('.');
    //       const jsDate = new Date(Date.UTC(parts[2], parts[1] - 1, parts[0]));
    //       console.log('date selected ' + date);
    //     }
    //   });
    // }, 1);
  }

  handleAction(action: string): void {
    if (action === 'save') {
      this.save();
    } else if (action === 'delete') {
      this.delete();
    }
  }

  onCompleted() {
    if (this.task.completionDate) {
      this.task.completionDate = null;
    } else {
      this.task.completionDate = new Date();
    }
    this.navigateToOverview = true;
    this.save();
  }


  onCreateTag() {
    setTimeout((() => {
      // @ts-ignore
      $('#tagModal').on('shown.bs.modal', () => {
        // @ts-ignore
        $('#taginput').trigger('focus');
      }).modal();
    }), 0);
  }

  cancelRecurring() {
    this.isRecurring = false;
    // @ts-ignore
    $('#recurringModal').modal('hide');
  }

  confirmRecurring() {
    this.isRecurring = true;
    // @ts-ignore
    $('#recurringModal').modal('hide');
  }

  showRecurring() {
    setTimeout((() => {
      // @ts-ignore
      $('#recurringModal').on('shown.bs.modal', () => {
      }).modal();
    }), 0);
  }


  onCreateTagConfirmed(): void {
    console.log(this.newTagname);
    const tag = {
      id: undefined,
      name: this.newTagname
    };
    this.tagService.createTag(tag).subscribe({
      next: createdTag => {
        this.tags.push(createdTag);
        // @ts-ignore
        $('#tagModal').modal('hide');
      },
      error: error => console.log(error)
    });
  }

  setupRecurrence(): void {
    if (this.isRecurring) {
      if (this.endsOption === 1) {
        this.recurrence.maxOccurrences = undefined;
      } else if (this.endsOption === 2) {
        this.recurrence.endDate = undefined;
      } else {
        this.recurrence.endDate = undefined;
        this.recurrence.maxOccurrences = undefined;
      }


      if (this.recurrence.type == 1) {
        const result = []; // 1-7, SUN - SAT
        this.weekdays.forEach((x, i) => {
          if (x) {
            result.push((i + 1) % 8);
          }
        });
        this.recurrence.days = result.toString();
      } else if (this.recurrence.type == 2) {
        if (this.monthRepeatOption === 0) {
          this.recurrence.days = this.daysOfSpecificMonthDay.toString();
          this.recurrence.monthEvery = undefined;
        } else {
          this.recurrence.monthEvery = this.monthEvery;
          this.recurrence.days = this.daysOfMonthEvery.toString();
        }
      }
      this.task.recurrence = this.recurrence;
    } else {
      this.task.recurrence = undefined;
    }
  }

  save(): void {
    this.setupRecurrence();
    if (this.selectedId) {
      this.tasklistService.createTask(this.tasklistId, this.task).subscribe({
        // next: doc => this.router.navigate(['tasks'],
        //   {queryParams: {tasklist: this.tasklistId, filter: this.filter}}), // this.onLoadSuccess(doc)
        next: doc => {
          this.task = doc;
          this.router.navigate(['task/' + doc.id], {replaceUrl: true, queryParams: {tasklist: this.tasklistId}});
          showSnackbar('Erfolgreich gespeichert');
        },
        error: err => console.log(err)
      });
    } else {
      this.tasklistService.createTask(this.tasklistId, this.task).subscribe({
        next: doc => {
          this.task = doc;
          this.router.navigate(['task/' + doc.id], {replaceUrl: true, queryParams: {tasklist: this.tasklistId}});
          showSnackbar('Erfolgreich gespeichert');
        },
        // next: task => this.router.navigate(['tasks'], {queryParams: {tasklist: this.tasklistId}}), // this.navigateToTask(task)
        // next: task => this.router.navigate(['tasks'], {queryParams: {tasklist: this.tasklistId}}), // this.navigateToTask(task)
        error: err => console.log(err)
      });
    }
  }

  delete(): void {
    if (this.selectedId) {
      this.taskService.delete(this.task.id).subscribe({
        next: doc => this.router.navigate(['tasks'], {queryParams: {tasklist: this.tasklistId}}),
        error: err => console.log(err)
      });
    }
  }

  navigateToTask(task): void {
    this.router.navigate(['task/' + task.id], {queryParams: {tasklist: this.tasklistId}, replaceUrl: true});
  }

  onLoadSuccess(task): void {
    if (this.navigateToOverview) {
      this.router.navigate(['tasks'], {queryParams: {tasklist: this.tasklistId}});
      return;
    }
    if (task) {
      this.selectedId = task.id;
      this.task = task;
      if (task.recurrence) {
        this.isRecurring = true;
        this.recurrence = task.recurrence;
        this.onTypeChanged(task.recurrence.type);
        if (this.recurrence.type === 1) {
          this.weekdays[0] = this.recurrence.days.includes('1');
          this.weekdays[1] = this.recurrence.days.includes('2');
          this.weekdays[2] = this.recurrence.days.includes('3');
          this.weekdays[3] = this.recurrence.days.includes('4');
          this.weekdays[4] = this.recurrence.days.includes('5');
          this.weekdays[5] = this.recurrence.days.includes('6');
          this.weekdays[6] = this.recurrence.days.includes('7');
        } else if (this.recurrence.type === 2) {

          if (this.recurrence.monthEvery) {
            this.monthEvery = this.recurrence.monthEvery;
            this.daysOfMonthEvery = parseInt(this.recurrence.days, 10);
            this.monthRepeatOption = 1;
          } else {
            this.monthEvery = 1;
            this.daysOfSpecificMonthDay = parseInt(this.recurrence.days, 10);
            this.monthRepeatOption = 0;
            this.daysOfMonthEvery = 2;
          }

        }
        if (!this.recurrence.endDate && !this.recurrence.maxOccurrences) {
          this.endsOption = 0;
        } else if (this.recurrence.endDate) {
          this.endsOption = 1;
        } else {
          this.endsOption = 2;
        }
      }
      this.eventBus.emitDataLoaded(this.task);

      // if (task.recurrence) {
      //   this.isRecurring = true;
      // }

      // setup height of textarea
      if (this.task && this.task.description) {
        this.numberOfRows = Math.max(this.numberOfRows, this.task.description.split('\n').length);
      }
      // if (this.numberOfRows > 20) {
      //   this.numberOfRows = 20;
      // }

      // sync available and used tags
      if (this.tags) {
        this.task.tags.forEach((fromTask, x, array) => {
          this.tags.forEach((fromTag, y, array2) => {
            if (fromTask.id === fromTag.id) {
              array2.splice(y, 1);
            }
          });
        });
      }
      // resize textarea
      setTimeout(() => {
        let textarea = document.getElementById('task-description')
        if(textarea != undefined){
          textarea.style.height = textarea.scrollHeight + 5 + "px";
        }
      }, 1);
    }
  }

  onAddTag(tag: Tag) {
    for (const current of this.task.tags) {
      if (current.name === tag.name) {
        return;
      }
    }
    this.task.tags.push(tag);
    this.tags.splice(this.tags.indexOf(tag), 1);
  }


  onRemoveTag(tag: Tag) {
    let index = 0;
    this.task.tags.forEach(current => {
      if (current.id === tag.id) {
        this.tags.push(tag);
        this.task.tags.splice(index, 1);
        return;
      }
      index++;
    });
    // this.task.tags.push(tag);
    // const index = this.task.tags.indexOf(tag);
    // if (index > -1) {
    //   this.task.tags.splice(index, 1);
    // }
  }

  onLoadFail(task): void {

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

