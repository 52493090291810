import {ChangeDetectorRef, Component, ComponentFactoryResolver, NgZone, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Task} from '../data-model/task';
import {EventBusService} from '../event-bus.service';
import {TaskService} from '../data-services/task.service';
import {ActivatedRoute, Router} from '@angular/router';
import {getListForPositionUpdate, setupDragAndDrop, setupDropable} from '../util';
import {DragAndDrop} from '../data-model/drag_and_drop';
import {TasklistService} from '../data-services/tasklist.service';
import {Tasklist} from '../data-model/tasklist';
import {Droppable} from '../data-model/droppable';
import {EventbusEvent} from '../data-model/eventbus_event';
import {ModalQuestion} from '../data-model/modal_question';
import {EventbusEventType} from '../data-model/eventbus_event_type';
import {TasklistType} from '../data-model/tasklist_type';
import {EventBusResult} from '../data-model/evebt_bus_result';
import {KeyValue} from '@angular/common';
import {DropEvent} from '../data-model/drop_event';
import {DropPayload} from '../data-model/drop_payload';
import {SettingService} from '../data-services/setting.service';
import {Setting} from '../data-model/setting';
import {showSnackbar, writeFileWithFilename} from '../utility';
import {TasklistParent} from '../data-model/tasklist_parent';
import {EditManyTasks} from '../data-model/editManyTasks';

@Component({
  selector: 'app-task-overview',
  templateUrl: './task-overview.component.html',
  styleUrls: ['./task-overview.component.css']
})
export class TaskOverviewComponent implements OnInit, OnDestroy, DragAndDrop, Droppable {
  subscription: Subscription;
  menuActionsSubscription: Subscription;
  listIdTasksMapping;
  tasklist: Tasklist[];
  tasklistMap = {};
  isDragging = false;
  tasklistToCreate = '';
  selectedTasklistData: Tasklist[];
  selectedTasklist: Tasklist;
  currentTasklistId = null;
  currentTasklistFilter = null;
  tagsForTask: string[];
  listToEdit;
  newListName;
  lastTimeTaskDroppedToTasklist = 0;
  showAllLists = false;
  showTasklists: boolean = undefined;
  taskEdit: string;
  taskEditListId: string;
  taskEditIsAdd: boolean;
  progress: string = '0%';
  progressTitle: string = '';
  // Preserve original property order:
  // https://stackoverflow.com/questions/52793944/angular-keyvalue-pipe-sort-properties-iterate-in-order
  originalOrder = (a: KeyValue<number, any>, b: KeyValue<number, any>): number => {
    return 0;
  }

  toggleListVisibility() {
    return this.showTasklists = !this.showTasklists;
  }

  constructor(private eventBus: EventBusService,
              private componentFactoryResolver: ComponentFactoryResolver, private taskService: TaskService,
              private settingService: SettingService, private tasklistService: TasklistService, protected router: Router,
              protected route: ActivatedRoute, private cd: ChangeDetectorRef, private ngZone: NgZone) {
  }

  onShowAllLists() {
    this.showAllLists = !this.showAllLists;
  }

  isSublist(list: Tasklist): boolean {
    return list.hasParent;
  }

  isSystemCategory(list: Tasklist): boolean {
    if (list == null) {
      return true;
    }
    return list.type === TasklistType.SYSTEM_CATEGORY;
  }

  onUpdate(dropEvent: DropEvent): void {
    console.log('drop time is ' + String(Date.now() - this.lastTimeTaskDroppedToTasklist));
    if (Date.now() - this.lastTimeTaskDroppedToTasklist < 1000) {
      // a item was dropped onto a tasklist
      console.log('exit due to drop time is ' + String(Date.now() - this.lastTimeTaskDroppedToTasklist));
      return;
    }
    if (dropEvent.sourceList.startsWith('tasktable')) {
      if (this.currentTasklistFilter) {
        // no reorder for virtual lists
        return;
      }
      const sourceListId = dropEvent.sourceList.substring(dropEvent.sourceList.indexOf('-') + 1);
      const targetListId = dropEvent.targetList.substring(dropEvent.targetList.indexOf('-') + 1);
      const sortInSameList = sourceListId === targetListId;
      const sourceTable = this.listIdTasksMapping.get(!!sourceListId ? sourceListId : null);
      const targetTable = this.listIdTasksMapping.get(!!targetListId ? targetListId : null);
      const taskIdd = sourceTable[dropEvent.oldIndex].id;

      // dropped to the end of the list, add a item
      if (dropEvent.newIndex === targetTable.length && sortInSameList) {
        let k = dropEvent.newIndex - targetTable.length + 1;
        while (k--) {
          targetTable.push(undefined);
        }
      }
      if (sortInSameList) {
        targetTable.splice(dropEvent.newIndex, 0, targetTable.splice(dropEvent.oldIndex, 1)[0]);
      } else {
        targetTable.splice(dropEvent.newIndex, 0, sourceTable[dropEvent.oldIndex]);
        sourceTable.splice(dropEvent.oldIndex, 1);
      }

      for (let i = 0; i < targetTable.length; i++) {
        targetTable[i].position = i + 1;
      }
      if (!sortInSameList) {
        for (let i = 0; i < sourceTable.length; i++) {
          sourceTable[i].position = i + 1;
        }
      }

      const payload: DropPayload = {
        taskId: taskIdd,
        targetListId,
        positions: getListForPositionUpdate(targetTable)
      };

      this.taskService.updatePosition(payload).subscribe({
        next: tasks => {
          if (!sortInSameList) {
            this.tasklistService.getTasklists().subscribe({
              next: tasklist => {
                this.onTasklistsSuccessfullyLoaded(tasklist);
                dropEvent.draggedElement.remove(); // this is required due to UI update issues
              },
              error: err => console.log(err)
            });
          }
        },
        error: err => console.log(err)
      });
    } else if (dropEvent.sourceList === 'tasklists') {
      const droppedTasklist = this.tasklist[dropEvent.oldIndex];

      if (dropEvent.newIndex >= this.tasklist.length) {
        let k = dropEvent.newIndex - this.tasklist.length + 1;
        while (k--) {
          this.tasklist.push(undefined);
        }
      }
      this.tasklist.splice(dropEvent.newIndex, 0, this.tasklist.splice(dropEvent.oldIndex, 1)[0]);
      for (let i = 0; i < this.tasklist.length; i++) {
        this.tasklist[i].position = i + 1;
      }
      this.tasklistService.updatePositions(getListForPositionUpdate(this.tasklist)).subscribe({
        next: tasklist => console.log('Update Positions OK'),
        error: err => console.log(err)
      });
    }
  }

  onEvent(event: EventbusEvent): void {
    if (event.action === EventbusEventType.MODAL_RESULT) {
      if (event.payload) {
        if (!event.payload) {
          return;
        }
        const result: EventBusResult = event.payload as EventBusResult;
        if (result.action === EventbusEventType.DELETE_TASK) {
          this.taskService.delete(result.payload).subscribe({
            next: taskUpdated => {
              this.loadTasks();
              this.loadTasklist();
            },
            error: err => console.log(err)
          });
        } else if (result.action === EventbusEventType.DELETE_TASKLIST) {
          this.deleteTasklist(result.payload);
        }
      }
    } else if (event.action === EventbusEventType.CONTROL_N_PRESSED) {
      this.newTask();
      //setTimeout(() => {
      //  document.getElementById('newtask-input').focus();
      //}, 10);
    } else if (event.action === EventbusEventType.CONTROL_SHIFT_N_PRESSED) {
      this.addTasksViaText(this.selectedTasklist ? this.selectedTasklist.id : null);
      //setTimeout(() => {
      //  document.getElementById('newtask-input').focus();
      //}, 10);
    } else if (event.action === EventbusEventType.F2_PRESSED) {
      this.onRenameTasklist(this.selectedTasklist);
      //showSnackbar("F2 in tasks")
      //setTimeout(() => {
      //  document.getElementById('newtask-input').focus();
      //}, 10);
    }
  }

  copyTasklistToClipboard(list: Task[]) {
    let textToCopy = '';

    for (const task of list) {
      textToCopy = textToCopy + task.title + '\n';
    }
    navigator.clipboard.writeText(textToCopy);
    const newline = list.length > 1 ? '\n' : '';
    showSnackbar('Kopiert: ' + newline + textToCopy);
  }

  copyTaskToClipboard(task: Task) {
    let textToCopy = task.title;
    if (task.description) {
      textToCopy = task.description;
    }
    textToCopy = this.replaceAll(textToCopy, '\n', '\r\n');
    navigator.clipboard.writeText(textToCopy);
    showSnackbar('Kopiert: ' + textToCopy);
  }

  escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  editTasksViaText(listId) {
    this.taskEditIsAdd = false;
    this.taskEditListId = listId;
    if (listId === undefined) {
      listId = null;
    }
    const sourceList = this.listIdTasksMapping.get(listId);
    this.taskEdit = '';
    if (sourceList) {
      for (const task of sourceList) {
        this.taskEdit = this.taskEdit + '\n' + task.title;
      }
    }
    this.taskEdit = this.taskEdit.trim();

    // @ts-ignore
    $('#editTasks').on('hide.bs.modal', () => {
      // put your default event here
      console.log('modal closed');
    }).modal({
      // backdrop: 'static',
      // keyboard: false
    });
    setTimeout(() => { // this will make the execution after the above boolean has changed
      const element = document.getElementById('taskedit');
      if (!element) {
        return;
      }
      element.focus();
    }, 25);
  }

  addTasksViaText(listId) {
    this.taskEdit = '';
    this.taskEditIsAdd = true;
    if(listId){
      this.taskEditListId = listId;
    }
    // @ts-ignore
    $('#editTasks').on('hide.bs.modal', () => {
      // put your default event here
      console.log('modal closed');
    }).modal({
      // backdrop: 'static',
      // keyboard: false
    });
    setTimeout(() => { // this will make the execution after the above boolean has changed
      const element = document.getElementById('taskedit');
      if (!element) {
        return;
      }
      element.focus();
    }, 25);
  }

  cancelTaskEdit() {
    this.taskEdit = '';
  }

  confirmTaskEdit() {
    this.taskEdit = this.replaceAll(this.taskEdit, '\n\n', '\n');
    const taskTitles = this.taskEdit.split('\n');

    const editManyTasks: EditManyTasks = {tasks: [], tastklistId: this.taskEditListId};

    for (const i in taskTitles) {
      if (taskTitles[i] !== undefined && taskTitles[i].length > 0) {
        const theTask: Task = this.createTask(taskTitles[i], undefined);
        editManyTasks.tasks.push(theTask);
      }
    }
    if (this.taskEditIsAdd) {
      this.taskService.createMany(editManyTasks).subscribe({
        next: () => {
          this.taskEdit = '';
          this.loadTasks();
        },
        error: err => console.log(err)
      });
    } else {
      this.taskService.updateMany(editManyTasks).subscribe({
        next: () => {
          this.taskEdit = '';
          this.loadTasks();
        },
        error: err => console.log(err)
      });
    }

  }

  replaceAll(str, find, replace) {
    return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
  }

  newTask() {
    this.router.navigate(['task'], {
      queryParams: {
        tasklist: this.currentTasklistId,
        tag: (this.selectedTasklist && this.selectedTasklist.filter) ? this.selectedTasklist.filter.tags : undefined,
        filter: (this.selectedTasklist && this.selectedTasklist.filter) ? this.selectedTasklist.filter.filter : undefined
      }
    });
  }


  onContextMenu($event, menuAppendix, idSuffix): void {
    const contextMenuId = 'contextmenu-' + menuAppendix + '-' + idSuffix;
    // keep context menus for links
    const onClickListener = () => {
      const element2 = document.getElementById(contextMenuId);
      element2.classList.remove('show');
      document.body.removeEventListener('click', onClickListener);
    };

    // hide all context menus
    const contextmenus = document.querySelectorAll('*[id^="contextmenu-"]');
    contextmenus.forEach(menu => {
      menu.classList.remove('show');
    });

    if ($event.target.nodeName.toLowerCase() === 'a') {
      onClickListener();
      return;
    }
    $event.preventDefault();
    const element = document.getElementById(contextMenuId);
    element.classList.add('show');
    element.style.left = $event.clientX + 'px';
    element.style.top = $event.clientY + 'px';

    document.body.addEventListener('click', onClickListener);
  }

  // @HostListener('document:keypress', ['$event'])
  // handleKeyboardEvent(event: KeyboardEvent) {
  //   if (event.target instanceof HTMLInputElement) {
  //     return;
  //   }
  //   if (event.key === 'n') {
  //     setTimeout(() => {
  //       this.newTask();
  //     }, 100);
  //   }
  // }

  ngOnInit(): void {
    this.subscription = this.eventBus.eventFired$.subscribe({
      next: result => this.onEvent(result)
    });
    this.menuActionsSubscription = this.eventBus.menuActionChange$.subscribe({
      next: action => this.handleAction(action)
    });
    this.route.queryParams.subscribe(params => {
      this.currentTasklistId = (params.tasklist ? params.tasklist : undefined);
      this.currentTasklistFilter = params.filter;

      if (!!params.tag) {
        if (typeof params.tag === 'string' || params.tag instanceof String) {
          // @ts-ignore
          this.tagsForTask = [params.tag];
        } else if (Array.isArray(params.tag)) {
          this.tagsForTask = params.tag;
        }
      }
    });
    this.loadTasklist();
    this.loadTasks();
    setupDragAndDrop('tasklists', this);
    this.progress = this.getProgress();
    this.progressTitle = this.getProgressTitle();
  }

  onRenameTasklist(tasklist): void {
    // window.alert('Rename ' + tasklist.name);
    if (!tasklist.id) {
      return;
    }
    this.listToEdit = tasklist;
    this.newListName = tasklist.name;
    this.cd.detectChanges();

    setTimeout(() => { // this will make the execution after the above boolean has changed
      const element = document.getElementById('editlist-' + tasklist.id);
      if (!element) {
        return;
      }
      element.focus();
    }, 25);
  }

  listHasParent(list: Tasklist) {
    return list.hasParent;
  }

  onClearParent(tasklist): void {
    tasklist.parent = null;
    let tasklistparent: TasklistParent = {
      parentTasklistId: null,
      tasklistId: tasklist.id
    };
    this.tasklistService.updateParent(tasklistparent).subscribe({
      next: listUpdated => {
        this.loadTasklist();
      },
      error: err => console.log(err)
    });
  }

  onRenameTasklistConfirmed() {
    this.listToEdit.name = this.newListName;
    const patchObject = {
      name: this.newListName
    };

    this.tasklistService.patch(this.listToEdit.id, patchObject).subscribe({
      next: listUpdated => {
        for (let i = 0; i < this.tasklist.length; i++) {
          if (this.tasklist[i].id === listUpdated.id) {
            this.tasklist[i].name = listUpdated.name;
            this.tasklist[i].version = listUpdated.version;
            break;
          }
        }
      },
      error: err => console.log(err)
    });

    this.listToEdit = null;
  }

  onRenameTasklistCanceled() {
    this.listToEdit = null;
  }

  onScheduleTaskForToday(task: Task) {
    this.scheduleTask(task, new Date());
  }

  onScheduleTaskForTomorrow(task: Task) {
    const dateForTomorrow = new Date();
    dateForTomorrow.setDate(dateForTomorrow.getDate() + 1);
    this.scheduleTask(task, dateForTomorrow);

  }

  scheduleTask(task: Task, date: Date) {
    task.dueDate = date;

    this.tasklistService.createTask(task.tasklist.id, task).subscribe({
      next: updatedTask => {
        this.loadTasklist();
      },
      error: err => console.log(err)
    });
  }

  onDeleteTask(task: Task): void {
    const data: ModalQuestion = {
      cancelText: 'Abbrechen',
      confirmClass: 'btn-danger',
      confirmText: 'Löschen',
      message: 'Möchten Sie die Aufgabe <b>' + task.title + '</b> löschen?',
      title: 'Löschen',
      focusOnPrimary: false,
      result: {
        action: EventbusEventType.DELETE_TASK,
        payload: task.id
      }
    };
    const event: EventbusEvent = {
      action: EventbusEventType.MODAL_QUESTION,
      payload: data
    };
    this.eventBus.emitEvent(event);
  }

  onDeleteTaskConfirmed(): void {

  }

  handleAction(action: string): void {
    if (action === 'new') {
      this.newTask();
      return;
    } else if (action === 'rename') {
      this.onRenameTasklist(this.selectedTasklist);
      return;
    } else if (action === 'share') {
      if (this.currentTasklistId) {
        this.router.navigate(['tasks/' + this.currentTasklistId + '/access']);
      }
      return;
    } else if (action === 'delete') {
      if (!this.currentTasklistId) {
        const modalInfo: ModalQuestion = {
          cancelText: null,
          focusOnPrimary: true,
          confirmClass: 'btn-light',
          confirmText: 'Ok',
          message: 'Diese Aufgabenliste kann nicht gelöscht werden',
          title: 'Löschen'
        };

        this.eventBus.emitEvent({action: EventbusEventType.MODAL_INFO, payload: modalInfo});
        return;
      }

      this.onDeleteTaskList(this.selectedTasklist);
    }
  }

  onDeleteTaskList(list: Tasklist) {
    const data: ModalQuestion = {
      cancelText: 'Abbrechen',
      confirmClass: 'btn-danger',
      confirmText: 'Löschen',
      message: 'Möchten Sie die ' + (this.isCategory(list) ? 'Kategorie' : 'Aufgabenliste') + ' <b>' + list.name + '</b> löschen?',
      title: 'Löschen',
      focusOnPrimary: false,
      result: {
        action: EventbusEventType.DELETE_TASKLIST,
        payload: list.id
      }
    };
    const event: EventbusEvent = {action: EventbusEventType.MODAL_QUESTION, payload: data};
    this.eventBus.emitEvent(event);
  }

  onCreateList(isChildList: boolean) {
    if ((isChildList && !this.tasklistToCreate) || this.tasklistToCreate.length === 0) {
      return;
    }
    let newList: Tasklist;

    let parentList;
    let newListPosition;

    if (!isChildList) {
      newListPosition = this.tasklist.length;
    } else {
      parentList = this.selectedTasklist;
      newListPosition = this.selectedTasklist.position;
    }

    newList = {
      id: null,
      name: this.tasklistToCreate,
      parent: parentList,
      position: newListPosition,
      type: TasklistType.TASKLIST,
      filter: null,
      numberOfTasks: 0,
      isOwner: true,
      isShared: false,
      version: 0
    };
    this.tasklistService.create(newList).subscribe({
      next: tasklist => this.onTasklistCreated(tasklist, isChildList),
      error: err => console.log(err)
    });
    setupDropable(this);
  }

  getListTitle(item) {
    return item.value[0] && item.value[0].tasklist ? item.value[0].tasklist.name : 'Inbox';
  }

  getListId(item) {
    return item.value[0] && item.value[0].tasklist ? item.value[0].tasklist.id : this.selectedTasklist;
  }

  isEditingList(list) {
    if (!this.listToEdit) {
      return false;
    }
    return this.listToEdit.id === list.id && this.listToEdit.name === list.name && this.listToEdit.filter === list.filter;
  }

  isSharedList(list) {
    return !!list && (!list.isOwner || list.isShared);
  }

  isCategory(list: Tasklist): boolean {
    if (list == null) {
      return true;
    }
    return list.type === TasklistType.CATEGORY || list.type === TasklistType.SYSTEM_CATEGORY;
  }

  deleteTasklist(id: number) {
    if (!id) {
      return;
    }
    this.tasklistService.delete(id).subscribe({
      next: tasklist => this.removeTasklist(id),
      error: err => console.log(err)
    });
  }

  getListById(id: string): Tasklist {
    if (!this.tasklistMap) {
      return undefined;
    }
    return this.tasklistMap[id];
  }

  listExpandChanged(listId: string, tableId: string) {
    setTimeout(() => {
      const element = document.getElementById(tableId);
      if (!element) {
        return;
      }
      const isExpanded = element.classList.contains('show');
      const setting: Setting = {tasklistId: listId, key: 'list_expanded', value: isExpanded.toString()};

      const list = this.getListById(listId);
      if (list) {
        if (!list.settings) {
          list.settings = {};
        }
        list.settings.list_expanded = isExpanded.toString();
      }

      this.settingService.save(setting).subscribe({
        next: result => {
        }
      });
    }, 100);
  }

  isExpanded(listId: string): boolean {
    const tasklist = this.getListById(listId);

    if (tasklist && tasklist.settings && tasklist.settings.list_expanded) {
      return tasklist.settings.list_expanded.toString() === 'true';
    }

    return true;
  }

  isSelectedTasklist(list) {
    const listid = list.id;
    if (!listid && !this.currentTasklistId && list.type !== 10) {
      return true;
    }
    if (!!listid && !!this.currentTasklistId) {
      return listid === this.currentTasklistId;
    }
    return false;
  }

  onTasklistSelected(list: Tasklist) {
    if (list.id === this.currentTasklistId || this.isDragging) {
      return;
    }
    this.showTasklists = false;
    this.selectedTasklist = list;
    this.currentTasklistId = list.id;
    this.currentTasklistFilter = list.filter;

    if (list.id != null && list.id !== undefined) {
      writeFileWithFilename('tasklist.txt', String(list.id));
    } else {
      writeFileWithFilename('tasklist.txt', "");
    }

    const parameter: { [k: string]: any } = {};
    parameter.tasklist = list.id;
    if (!parameter.tasklist && list.filter) {
      parameter.filter = list.filter.filter;
      parameter.tags = list.filter.tags;
    }

    // routing breaks after drag and drop a task to a list
    // https://stackoverflow.com/questions/53133544/angular-7-routerlink-directive-warning-navigation-triggered-outside-angular-zon
    this.ngZone.run(() => this.router.navigate(['tasks'], {
      queryParams: parameter,
      replaceUrl: true
    })).then();

    this.loadTasks();
    if (this.isInbox()) {
      setTimeout(() => {
        document.getElementById('newtask-input').focus();
      }, 10);
    }
  }

  isInbox() {
    return !this.currentTasklistId || (this.selectedTasklist && this.selectedTasklist.type !== 20 && this.selectedTasklist.type !== 30);
  }

  createTask(titleForTask, tasklist): Task {
    const task2Create: Task = {
      version: 0,
      id: null,
      title: titleForTask,
      description: '',
      dueDate: null,
      reminderDate: null,
      completionDate: null,
      position: 0,
      tasklist: null,
      tags: null
    };
    if (tasklist) {
      task2Create.tasklist = tasklist;
    }
    return task2Create;
  }

  saveTask(intputField) {
    const titleForTask = intputField.value;
    if (!titleForTask) {
      return;
    }
    const task2Create: Task = this.createTask(titleForTask, this.selectedTasklist);

    this.tasklistService.createTask(this.currentTasklistId, task2Create).subscribe({
      next: task => {
        intputField.value = '';
        this.loadTasks();
      },
      error: err => console.log(err)
    });

  }

  private removeTasklist(id: number) {
    this.loadTasklist();
    if (String(this.currentTasklistId) === String(id)) {
      this.router.navigate(['tasks'], {replaceUrl: true});
      this.currentTasklistId = null;
      this.currentTasklistFilter = null;
      this.loadTasks();
    }
  }

  private onTasklistCreated(tasklist, isChildList) {
    if (isChildList) {
      this.loadTasks();
      this.loadTasklist();
      return;
    }
    this.tasklistToCreate = '';
    this.selectedTasklist = tasklist;
    this.currentTasklistId = tasklist.id;
    this.currentTasklistFilter = null;
    this.selectedTasklist = tasklist;
    this.router.navigate(['tasks'], {queryParams: {tasklist: tasklist.id}, replaceUrl: true});
    this.loadTasks();
    this.loadTasklist();
  }

  private onTasklistsSuccessfullyLoaded(tasklist) {
    for (const list of tasklist) {
      if (list.id) {
        this.tasklistMap[list.id] = list;
      }
    }
    this.tasklist = tasklist;
    // console.log(JSON.stringify(this.tasklistMap));
    setupDropable(this);

    if (this.currentTasklistId) {
      for (let i = 0; i < this.tasklist.length; i++) {
        if (this.tasklist) {
          if (this.tasklist[i].id === this.currentTasklistId) {
            this.selectedTasklist = this.tasklist[i];
            break;
          }
        }
      }
    }

    this.cd.detectChanges();
  }

  private loadTasklist(): void {
    this.tasklistService.getTasklists().subscribe({
      next: tasklist => this.onTasklistsSuccessfullyLoaded(tasklist),
      error: err => console.log(err)
    });
  }

  private loadTasks(): void {
    console.log('Loading tasks');
    const list: Tasklist = {
      id: this.currentTasklistId,
      filter: this.currentTasklistFilter,
      name: '',
      numberOfTasks: 0,
      isOwner: true,
      isShared: false,
      parent: undefined,
      position: 0,
      type: 0,
      version: 0
    };

    let sort = '';
    if (list.id === '-100') {
      // planned tasks
      sort = 'dueDate';
    }
    this.tasklistService.getTasklistWithTasks(list.id).subscribe({
      next: tasklist => this.setTasks(tasklist),
      error: err => console.log(err)
    });
    // this.taskService.findTasks("design").subscribe({
    //   next: tasks => {
    //     console.log(JSON.stringify(tasks));
    //   },
    //   error: err => console.log(err)
    // });
  }

  setTasks(list: Tasklist): void {
    this.selectedTasklistData = [];
    this.selectedTasklistData.push(list);
    this.listIdTasksMapping = new Map();
    this.listIdTasksMapping.set(list.id, list.tasks);
    let sublist;
    for (sublist of list.sublists) {
      this.selectedTasklistData.push(sublist);
      this.listIdTasksMapping.set(sublist.id, sublist.tasks);
    }

    // drag and drop only for inbox when systemfilter is selected
    setTimeout(() => {
      const tables = document.querySelectorAll('*[id^="tasktable-"]');
      tables.forEach(table => {
        setupDragAndDrop(table.id, this);
      });
    }, 100);

    // this.cd.detectChanges();
  }

  getProgressTitle(): string {
    const today = new Date();
    const startDate = new Date('2023-04-10');
    const endDate = new Date('2023-05-05');
    while (today.getTime() > endDate.getTime()) {
      startDate.setDate(startDate.getDate() + 28);
      endDate.setDate(endDate.getDate() + 28);
    }
    if (endDate.getTime() < today.getTime()) {
      return 'die Zeit ist um';
    }
    const diffTime = Math.abs(endDate.getTime() - today.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    const diffTimeAll = Math.abs(endDate.getTime() - startDate.getTime());
    const diffDaysAll = Math.ceil(diffTimeAll / (1000 * 60 * 60 * 24));
    return 'noch ' + (diffDays) + ' von ' + diffDaysAll + ' Tagen';
  }

  getProgress(): string {
    const today = new Date();
    const startDate = new Date('2023-04-10');
    const endDate = new Date('2023-05-05');
    while (today.getTime() > endDate.getTime()) {
      startDate.setDate(startDate.getDate() + 28);
      console.log("Start date updated and is now " + startDate);
      endDate.setDate(endDate.getDate() + 28);
    }
    const result = Math.min(100, (100 * (new Date().getTime() - startDate.getTime())) / (endDate.getTime() - startDate.getTime()));
    if (result < 0) {
      return '0%'
    }
    return result + '%';
  }

  onTaskSelected(id) {
    if (this.isDragging) {
      return;
    }
    this.router.navigate(['task/' + id], {queryParams: {tasklist: this.currentTasklistId}});
  }

  onCompleteTaskPressed(event, tasklist, task, taskIndex): void {
    event.stopPropagation();
    let patch = {
      completionDate: null
    }
    if (!task.completionDate) {
      patch.completionDate = new Date();
    }
    this.tasklistService.patchTask(tasklist.id, task.id, patch).subscribe({
      next: taskUpdated => this.taskCompletedSuccessful(tasklist, taskUpdated, taskIndex),
      error: err => console.log(err)
    });
  }

  taskCompletedSuccessful(tasklist, updatedTask, i) {
    tasklist.splice(i, 1);
    this.loadTasklist();
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.menuActionsSubscription.unsubscribe();
  }

  onDrag(): void {
    this.isDragging = true;
  }

  onDrop(): void {
    setTimeout(() => {
      this.isDragging = false; // to make sure click event will no be fired
    }, 100);
  }

  isOverdue(dueDate) {
    if (dueDate) {
      const today = new Date();
      dueDate = Date.parse(dueDate);
      today.setHours(0, 0, 0, 0);
      return dueDate < today;
    }
    return false;
  }

  private onTaskMovedSuccess(task) {
    console.log('TASK MOVED ' + task.title);
    this.loadTasks();
    this.loadTasklist();
  }

  private onTaskMovedFailed(sourceList, indexOfDroppedElement, task) {
    sourceList.splice(indexOfDroppedElement, 0, task);
    this.cd.detectChanges();
  }

  onDropped(selectedTasklistId: string, indexOfDroppedElement: number, sourceTasklist: string): void {
    this.lastTimeTaskDroppedToTasklist = Date.now();
    // if (selectedTasklistId === this.currentTasklistId) {
    //   return;
    // } else
    if (parseInt(selectedTasklistId, 10) < 0) {
      // this is a system list, we can't drop tasks here
      return;
    }
    console.log('index is ' + indexOfDroppedElement);

    const listKey = !sourceTasklist ? null : sourceTasklist;
    const sourceList = this.listIdTasksMapping.get(listKey);
    const toBeUpdated = sourceList[indexOfDroppedElement];
    const copyForRestore = JSON.parse(JSON.stringify(toBeUpdated));
    toBeUpdated.tasklist = {id: selectedTasklistId};

    const payload: DropPayload = {
      taskId: toBeUpdated.id,
      targetListId: selectedTasklistId,
      positions: []
    };

    sourceList.splice(indexOfDroppedElement, 1); // delete from list and re-add in failure case
    this.cd.detectChanges();

    this.taskService.updatePosition(payload).subscribe({
      next: task => this.onTaskMovedSuccess(task),
      error: err => this.onTaskMovedFailed(sourceList, indexOfDroppedElement, copyForRestore)
    });

    // this.tasklistService.createTask(toBeUpdated.tasklist.id, toBeUpdated).subscribe({
    //   next: task => this.onTaskMovedSuccess(task),
    //   error: err => this.onTaskMovedFailed(sourceList, indexOfDroppedElement, copyForRestore)
    // });
  }
}
