import { Component, OnInit } from '@angular/core';
import {EventBusService} from '../event-bus.service';

@Component({
  selector: 'app-task-overview-menu',
  templateUrl: './task-overview-menu.component.html',
  styleUrls: ['./task-overview-menu.component.css']
})
export class TaskOverviewMenuComponent implements OnInit {

  constructor(private eventBus: EventBusService) { }

  ngOnInit(): void {
  }

  action(action: string): void {
    this.eventBus.emitMenuAction(action);
  }
}
