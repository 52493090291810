import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {EventBusService} from '../event-bus.service';
import {EventbusEventType} from '../data-model/eventbus_event_type';

@Component({
  selector: 'app-task-menu',
  templateUrl: './task-menu.component.html',
  styleUrls: ['./task-menu.component.css']
})
export class TaskMenuComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  data: any;
  saveText = 'Speichern';

  constructor(private eventBus: EventBusService) {
  }

  ngOnInit(): void {
    this.subscription = this.eventBus.dataLoaded$.subscribe({
      next: data => {
        console.log('Data received');
        this.data = data;
        this.saveText = 'Speichern';
      }
    });
  }

  action(action: EventbusEventType): void {
    this.eventBus.emitMenuAction(action);
    this.eventBus.emitEvent({action: action, payload: {}});
  }

  // gettest(): string {
  //   if (this.document && !this.document.immutable_since) {
  //     return 'data is ok';
  //   }
  //   return 'NOT ok';
  // }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  protected readonly EventbusEventType = EventbusEventType;
}
