<div style="margin-right: 25px">


  <div class="btn-group">
    <button type="button" class="btn btn-light dropdown-toggle " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      Mehr
    </button>
    <div class="dropdown-menu" >
      <div class="btn dropdown-item" (click)="action('rename')">Umbenennen</div>
      <div class="dropdown-divider"></div>
      <div class="btn dropdown-item" (click)="action('share')">Freigeben…</div>
      <div class="dropdown-divider"></div>
      <div class="btn dropdown-item" (click)="action('delete')"><i class="bi-trash" role="img"></i> Löschen</div>
    </div>
  </div>

  <button class="btn btn-primary" (click)="action('new')">Neue Aufgabe</button>
</div>
