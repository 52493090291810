import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {PersonService} from '../data-services/person.service';
import {DataService} from '../data-services/dataservice';
import {Entity} from '../data-model/entity';
import {ServiceFactory} from '../data-services/service.factory';

@Component({
  selector: 'app-link-picker',
  templateUrl: './link-picker.component.html',
  styleUrls: ['./link-picker.component.css']
})
export class LinkPickerComponent implements OnInit, OnChanges {

  service: DataService;

  constructor(private personService: PersonService, private factory: ServiceFactory) {
  }

  persons: Entity[];
  @Input() column = '';
  @Input() entity: string;
  @Input() value: Entity = {id: -1};
  @Output() valueChange = new EventEmitter<any>();

  ngOnInit(): void {
    this.service = this.factory.get(this.entity);

    this.service.getAll().subscribe({
      next: persons => this.persons = persons,
      error: err => console.log(err)
    });

    // this.personService.getPersons().subscribe({
    //   next: persons => this.persons = persons,
    //   error: err => console.log(err)
    // });
  }

  updateValue(event): void {
    for (const pers of this.persons) {
      if (String(pers.id) === event.target.value) {
        this.value = pers;
        this.valueChange.emit(this.value);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.service = this.factory.get(this.entity);

    this.service.getAll().subscribe({
      next: persons => this.persons = persons,
      error: err => console.log(err)
    });
  }

}
