<div class="my-3 p-3 bg-white rounded shadow-sm">

  <div style="margin-bottom: 15px; width: 100%; height: 0px; border-top: 2px dashed #cecece; border-radius: 10px; overflow: visible;" [title]="progressTitle">
    <div style="width: 0.7rem; position: relative; border-radius: 100px; height: 0.7rem; margin-top: -0.4rem; border: 3px solid black" [style.margin-left]="progress"></div>
  </div>

<!--  tasklist Small screen-->
  <div class="d-block d-sm-block d-lg-none">
    <button (click)="toggleListVisibility()" style="display: block" class="btn"><i class="bi bi-list"></i></button>
    <div [style.display]="this.showTasklists ? 'block' : 'none'">

    <div class="col-12" style="overflow: hidden;" >
      <ul style="cursor:default;" class="nav flex-column nav-pills" role="tablist">
        <li [class.disable-sorting]="(!list.id && !isCategory(list)) || !list.isOwner" (dblclick)="onRenameTasklist(list)"
            (contextmenu)="onContextMenu($event,'tasklist', (list.id)?list.id:list.name)" style="width: 100%"
            class="nav-item tasklists-item" [class.sublist]="isSublist(list) && !showAllLists" [class.user-category]="!isSystemCategory(list) && !showAllLists" [class.system-category]="isSystemCategory(list)"
            *ngFor="let list of tasklist;let i = index"
            [attr.data-tasklist]=list.id [attr.data-filter]=list.filter>
          <!--        [class]="i===0?'nav-link active':'nav-link'"-->
          <span *ngIf="!isEditingList(list) && !isSystemCategory(list)" (click)="onTasklistSelected(list)"
                class="nav-link tasklists-item-link"
                [class.active]="isSelectedTasklist(list)"
                data-toggle="pill" role="tab"
                aria-selected="true">{{list.name}} <i *ngIf="isSharedList(list) " style="opacity: 0.3;margin-left: 5px;" class="bi bi-people"></i>
            <span class="numberOfTasks" [style.visibility]="(list.numberOfTasks < 0 ) ? 'hidden' : 'visible'">{{list.numberOfTasks}}</span>
          </span>
          <span *ngIf="!isEditingList(list)  && isSystemCategory(list)" (click)="onTasklistSelected(list)"
                [class.active]="isSelectedTasklist(list)"
                class="nav-link tasklists-item-link category" role="tab">{{list.name}}
          </span>
          <!--          <a (click)="deleteTasklist(list.id)" class="btn delete-button"><i class="bi-trash" role="img"></i></a>-->
          <input (focusout)="onRenameTasklistCanceled()" (keydown.enter)="onRenameTasklistConfirmed()"
                 (keydown.escape)="onRenameTasklistCanceled()" type="text"
                 [(ngModel)]='newListName' *ngIf="isEditingList(list)">
        </li>
      </ul>

      <div style="margin-top: 1rem; padding: 3px 0;">
        <input class="form-control form-control-sm" style="width: 100%" type="text" [(ngModel)]="tasklistToCreate"
               (keydown.enter)="onCreateList(false)" (keydown.shift.enter)="onCreateList(true)"/>
        <a class="btn btn-link" (click)="onShowAllLists()">Alle anzeigen</a>
      </div>
    </div>

    </div>
  </div>

<!--  tasklist normal screen-->
  <div class="row">
    <div class="col-3 d-none d-lg-block d-sm-block]" style="overflow: hidden; border-right: 1px solid #ddd" >
      <ul style="cursor:default;" id="tasklists" class="nav flex-column nav-pills" role="tablist">
        <li [class.disable-sorting]="(!list.id && !isCategory(list)) || !list.isOwner" (dblclick)="onRenameTasklist(list)"
            (contextmenu)="onContextMenu($event,'tasklist', (list.id)?list.id:list.name)" style="width: 100%"
            class="nav-item tasklists-item" [class.sublist]="isSublist(list) && !showAllLists" [class.user-category]="!isSystemCategory(list) && !showAllLists" [class.system-category]="isSystemCategory(list)"
            *ngFor="let list of tasklist;let i = index"
            [attr.data-tasklist]=list.id [attr.data-filter]=list.filter>
          <!--        [class]="i===0?'nav-link active':'nav-link'"-->
          <span *ngIf="!isEditingList(list) && !isSystemCategory(list)" (click)="onTasklistSelected(list)"
                class="nav-link tasklists-item-link"
                [class.active]="isSelectedTasklist(list)"
                data-toggle="pill" role="tab"
                aria-selected="true">{{list.name}} <i *ngIf="isSharedList(list)" style="opacity: 0.3;margin-left: 5px;" class="bi bi-people"></i>
            <span class="numberOfTasks" [style.visibility]="(list.numberOfTasks < 0 ) ? 'hidden' : 'visible'">{{list.numberOfTasks}}</span>
          </span>

          <span *ngIf="!isEditingList(list)  && isSystemCategory(list)" (click)="onTasklistSelected(list)"
                [class.active]="isSelectedTasklist(list)"
                class="nav-link tasklists-item-link category" role="tab">{{list.name}}
          </span>
          <!--          <a (click)="deleteTasklist(list.id)" class="btn delete-button"><i class="bi-trash" role="img"></i></a>-->
          <input (focusout)="onRenameTasklistCanceled()" (keydown.enter)="onRenameTasklistConfirmed()"
                 (keydown.escape)="onRenameTasklistCanceled()" id="{{'editlist-'+list.id}}" type="text"
                 [(ngModel)]='newListName' *ngIf="isEditingList(list)">
        </li>
      </ul>

      <div style="margin-top: 1rem; padding: 3px 0;">
        <input class="form-control form-control-sm" style="width: 100%" type="text" [(ngModel)]="tasklistToCreate"
               (keydown.enter)="onCreateList(false)" (keydown.shift.enter)="onCreateList(true)"/>
        <button class="btn btn-link" (click)="onShowAllLists()">Alle anzeigen</button>
      </div>
    </div>

    <!-- we use visibility instead of ngIF because ngIf kills the dragAndDrop event handlers    -->

    <!--    <table style="width: 100%;">-->
    <!--      <thead>-->
    <!--      <th style="color: #aaa">Offene Aufgaben ({{tasks.length}})</th>-->
    <!--      </thead>-->
    <!--      <tbody id="tasks">-->
    <!--      &lt;!&ndash;        (click)="onTaskSelected(task.id)"&ndash;&gt;-->
    <!--      <tr (contextmenu)="onContextMenu($event, 'task', task.id)" class="taskrow"-->
    <!--          *ngFor="let task of tasks;let i = index">-->
    <!--        &lt;!&ndash;          <td>{{task.title}}</td>&ndash;&gt;-->
    <!--        <td><a routerLink="/task/{{task.id}}" [queryParams]="{tasklist: currentTasklistId,filter: currentTasklistFilter}">{{task.title}}</a></td>-->
    <!--        <td class="hide-during-dragging"-->
    <!--            style="width: 6.5rem;">{{task.dueDate | date :'mediumDate' : 'GMT': 'DE'}}</td>-->
    <!--        <td class="hide-during-dragging" style="width: 2rem;">-->
    <!--          <div class="form-check">-->
    <!--            <input class="form-check-input" type="checkbox" id="done" [checked]="task.completionDate"-->
    <!--                   (click)="onCompleteTaskPressed($event, task, i)">-->
    <!--            <label class="form-check-label" for="done">-->
    <!--            </label>-->
    <!--          </div>-->
    <!--        </td>-->
    <!--      </tr>-->
    <!--      </tbody>-->
    <!--    </table>-->


    <div [style.visibility]="(!selectedTasklist || true) ? 'visible' : 'hidden'" class="col-sm-12 col-lg-9">

      <input id="newtask-input" placeholder="Neue Aufgabe erstellen…" title="Press Enter to add a new task" class="form-control form-control-sm" style="margin-bottom: 20px" autofocus (keydown.enter)="saveTask($event.target)" [style.display]="isInbox() ? 'block' : 'none'"/>
<!--      <div *ngFor="let item of tasksGroupedByList | keyvalue: originalOrder;let tableIndex = index">-->
        <div *ngFor="let currentTasklist of selectedTasklistData;let tableIndex = index">
        <table style="width: 100%; margin-bottom: 2rem">

          <thead>
          <th style="color: #aaa;">
            <a style="color: #aaa;" data-toggle="collapse" href="#tasktable-{{currentTasklist.id}}" role="button"
               [attr.aria-expanded]="isExpanded(currentTasklist.id)"
               (click)="listExpandChanged(currentTasklist.id,'tasktable-' + currentTasklist.id)"
               attr.aria-controls="tasktable-{{currentTasklist.id}}">
              <i class="pos-icon bi-chevron-down"></i>
              <i class="pos-icon bi-chevron-right"></i>
              {{currentTasklist.name}} ({{currentTasklist.tasks.length}})</a>
            <button type="button" class="btn btn-link" (click)="addTasksViaText(currentTasklist.id)">Neu</button>
            <button type="button" class="btn btn-link" (click)="editTasksViaText(currentTasklist.id)">Bearbeiten</button>
            <span (click)="copyTasklistToClipboard(currentTasklist.tasks)" class="copy-label">kopieren</span>
          </th>
          </thead>

          <tbody id="tasktable-{{currentTasklist.id}}"
                 [ngClass]="isExpanded(currentTasklist.id) ? 'connected-tasklist collapse show': 'connected-tasklist collapse'">
          <tr (contextmenu)="onContextMenu($event, 'task', task.id)" class="taskrow" [attr.data-tasklist]="currentTasklist.id"
              *ngFor="let task of currentTasklist.tasks;let taskIndex = index">
            <td><a routerLink="/task/{{task.id}}"
                   [queryParams]="{tasklist: currentTasklistId,filter: currentTasklistFilter}">{{task.title}}</a>
              <span (click)="copyTaskToClipboard(task)" class="copy-label">kopieren</span>
            </td>
            <td class="hide-during-dragging" style="width: 6.5rem;"><span
              [ngClass]="{'overdue-date': isOverdue(task.dueDate)}"
            >{{task.dueDate | date :'mediumDate' : 'GMT': 'DE'}}</span></td>

            <td class="hide-during-dragging" style="width: 2rem;">
              <div class="form-check">
                <input style="cursor: pointer;" class="form-check-input" type="checkbox" id="done"
                       [checked]="task.completionDate"
                       (click)="onCompleteTaskPressed($event, currentTasklist.tasks, task, taskIndex)">
                <label class="form-check-label" for="done">
                </label>
              </div>
            </td>
          </tr>
          </tbody>

        </table>
      </div>

      <br/>
      <br/>
      <!--class="header-caption"-->
<!--      currentTasklistId-->
<!--      <div *ngIf="false">-->
<!--        <a style="color: #aaa;" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false"-->
<!--           aria-controls="collapseExample"-->
<!--           style="color:black;font-size: 90%;">-->
<!--          <i class="pos-icon bi-chevron-down"></i>-->
<!--          <i class="pos-icon bi-chevron-right"></i>-->
<!--          Erledigte Aufgaben ({{completedTasks.length}})-->
<!--        </a>-->
<!--          <table style="width: 100%;">-->
<!--            <thead>-->
<!--            asd-->
<!--            </thead>-->
<!--            <div class="collapse" id="collapseExample">-->
<!--            <tbody>-->
<!--            &lt;!&ndash;          (click)="onTaskSelected(task.id)"&ndash;&gt;-->
<!--            <tr>def</tr>-->
<!--            <tr class="taskrow"-->
<!--                *ngFor="let task of completedTasks;let i = index">-->
<!--              &lt;!&ndash;            <td>{{task.title}}</td>&ndash;&gt;-->
<!--              <td><a routerLink="/task/{{task.id}}"-->
<!--                     [queryParams]="{tasklist: currentTasklistId, filter: currentTasklistFilter}">{{task.title}}</a>-->
<!--              </td>-->
<!--              <td style="widht: 6.5rem;">{{task.dueDate | date :'mediumDate' : 'GMT': 'DE'}}</td>-->
<!--              <td style="width: 2rem;">-->
<!--                <div class="form-check">-->
<!--                  <input style="cursor: pointer;" class="form-check-input" type="checkbox" id="done2" [checked]="task.completionDate"-->
<!--                         (click)="onCompleteTaskPressed($event, completedTasks, task, i)">-->
<!--                  <label class="form-check-label" for="done2">-->
<!--                  </label>-->
<!--                </div>-->
<!--              </td>-->
<!--            </tr>-->
<!--            </tbody></div>-->
<!--          </table>-->
<!--      </div>-->
    </div>
  </div>
</div>


<ng-container *ngFor="let item of listIdTasksMapping | keyvalue: originalOrder">
  <div id="contextmenu-task-{{task.id}}" *ngFor="let task of item.value;let i = index" class="dropdown-menu"
       aria-labelledby="dropdownMenuButton">

    <div class="dropdown-header"><b>Einplanen für</b></div>
    <div class="btn dropdown-item" (click)="onScheduleTaskForToday(task)">Heute</div>
    <div class="btn dropdown-item" (click)="onScheduleTaskForTomorrow(task)">Morgen</div>

    <div class="dropdown-divider"></div>

    <div class="dropdown-header"><b>Verschieben nach</b></div>
<!--    [style.display]="(list.id && list.id != currentTasklistId && !isCategory(list)) ? '': 'none'"-->
    <div *ngFor="let list of tasklist" class="btn dropdown-item"
         (click)="onDropped(list.id, i, item.key)">
      {{list.name}}
    </div>

    <div class="dropdown-divider"></div>
    <div class="btn dropdown-item" (click)="onDeleteTask(task)"><i class="bi-trash" role="img"></i> Löschen</div>
  </div>
</ng-container>

<div id="contextmenu-tasklist-{{(list.id)?list.id:list.name}}" *ngFor="let list of tasklist;let i = index"
     class="dropdown-menu"
     aria-labelledby="dropdownMenuButton">
  <div class="btn dropdown-item" (click)="onRenameTasklist(list)">Umbenennen</div>
  <div [hidden]="!listHasParent(list)" class="btn dropdown-item" (click)="onClearParent(list)">Kategorie -> Liste</div>
  <div class="dropdown-divider"></div>
  <div class="btn dropdown-item" (click)="onDeleteTaskList(list)"><i class="bi-trash" role="img"></i> Löschen</div>
</div>

<div id="editTasks" class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Aufgaben</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <textarea id="taskedit" [rows]="20" [(ngModel)]="taskEdit" class="form-control" style="width: 100%"></textarea>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-dismiss="modal">Abbrechen</button>
        <button (click)="confirmTaskEdit()" data-dismiss="modal" type="button" class="btn btn-primary">Ok</button>
      </div>
    </div>
  </div>
</div>

<button (click)="newTask()" class="btn btn-primary rounded-circle floating-add-button">
  <i class="bi bi-plus"></i>
</button>
